<template lang="pug">
//- scheduled-item
.scheduled-item
  .scheduled-item-title {{ course.title }}
  .scheduled-item-member-list
    .scheduled-item-member-item(v-for="n in course.course_student_use")
      img(src="@/assets/images/male@3x.png")
  .scheduled-item-content-box
    .scheduled-item-content
      .scheduled-item-info
        .info-time {{ course.start_time }} - {{ course.end_time }}
        .info-teacher {{ course.coures_teacher.name }}
      .scheduled-item-total 課程長度: {{ course.course_minutes }}分鐘
    .scheduled-item-btns
      .scheduled-item-btn_1(
        v-if="course.have_inter"
        @click="clickOption(course, 'inter')"
      ) 互動課程
      .scheduled-item-btn_2(
        v-if="course.have_live"
        @click="clickOption(course, 'live')"
      ) 直播課程

</template>
<script>
export default {
  name: 'courseItem',
  props: ['course'],
  components: {

  },
  data () {
    return {

    };
  },
  methods: {
    // 當用戶點擊課程按鈕
    clickOption (_course, _type) {
      console.log(_course)
      // 紀錄目前操作的課程ID
      this.course_id = _course.course_id
      this.process_course = _course
      // 請求互動課程
      if (_type === 'inter') {

        // 未開放互動課程
        if (!_course.have_inter) {
          this.$root.openOptionView({
            title: "未開放互動的課程",
            text_1: "這堂課程目前不開放互動教室",
            text_2: '',
            course_text: '',
            option_1: "確認",
            option_2: "",
            option_1_fun: false,
            option_2_fun: false
          })
          return
        }

        // 是否已經購買過課程
        if (_course.order_meeting) {
          this.registerCourse()
          return
        }

        // 人數已滿
        if (_course.course_student_use >= _course.course_student_max) {
          this.$root.openOptionView({
            title: "目前人數額滿",
            text_1: "您選擇的課程目前額滿",
            text_2: '',
            course_text: '',
            option_1: "確認",
            option_2: "",
            option_1_fun: false,
            option_2_fun: false
          })
          return
        }

        // 處理登記課程
        this.$root.openOptionView({
          title: "互動課程",
          text_1: "您選擇互動課程",
          text_2: '，確認進入教室？',
          text_3: '進食、運動、搭車路途中請進行『直播課程』學習。『互動課程』進行時，請務必確認環境音、耳機、麥克風、網路處於正常情況，上課中如有雜音則可能進行暫時關閉。',
          course_text: _course.title,
          option_1: "確認",
          option_2: "我再考慮",
          option_1_fun: this.registerCourse,
          option_2_fun: false
        })
        return
      }

      // 請求直播課程
      else if (_type === 'live') {

        // 未開放直播課程
        if (!_course.have_live) {
          this.$root.openOptionView({
            title: "未開放直播的課程",
            text_1: "這堂課程目前不開放直播教室",
            text_2: '',
            course_text: '',
            option_1: "確認",
            option_2: "",
            option_1_fun: false,
            option_2_fun: false
          })
          return
        }

        // 是否已經購買過課程
        if (_course.order_live) {
          this.openCourseLive()
          return
        }

        // 處理登記直播
        this.$root.openOptionView({
          title: "直播課程",
          text_1: "您選擇直播課程",
          text_2: '將會扣除企業帳戶 ' + this.$root.common.formatNumber(_course.course_live_point, 0) + ' 點數，確認進入直播？',
          course_text: _course.title,
          option_1: "確認",
          option_2: "我再考慮",
          option_1_fun: this.openCourseLive,
          option_2_fun: false
        })
        return
      }

      // 發生未知的問題
      else {
        this.$root.openOptionView({
          title: "發生未知的問題",
          text_1: "很抱歉，目前功能發生障礙",
          text_2: '請稍後再試',
          course_text: '',
          option_1: "瞭解",
          option_2: "",
          option_1_fun: false,
          option_2_fun: false
        })
        return
      }
    },
    // 請求預約團體班課程
    async registerCourse () {
      // 開啟載入中
      this.$root.useLoading()
      // 向後端請求預約課程
      await this.$root.apis.registerGroupCourse(this.course_id, this.processRegCourseSuccess, this.processRegCourseError)
      // 關閉載入中
      this.$root.closeLoading()
    },
    // 處理返回預約成功的資料
    processRegCourseSuccess (_response) {
      // 處理異常資料
      if (_response.body.error_code !== 0 && _response.body.error_code !== 3001) {
        return this.processRegCourseError(_response)
      }

      // 更新課程資料
      this.reLoadCourseList()

      // 進入會議室
      if (this.process_course.is_started) {
        this.$root.common.goMeet(0, this.course_id)
      }

      // 告知學員已經預約成功
      else {
        this.$root.openOptionView({
          title: "預約成功",
          text_1: '課程已經預約成功',
          text_2: '請在課程開始時進入會議室哦！',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
      }
    },
    // 處理返回預約失敗的資料
    processRegCourseError (_error) {
      this.$root.openOptionView({
        title: "扣點失敗",
        text_1: _error.body.message,
        text_2: '',
        course_text: '',
        option_1: "瞭解",
        option_2: '',
        option_1_fun: false,
        option_2_fun: false
      })
    },
    // 請求進入課程直播
    async openCourseLive () {
      // 開啟載入中
      this.$root.useLoading()
      // 向後端請求進入課程直播
      await this.$root.apis.liveGroupCourse(this.course_id, this.processLiveCourseSuccess, this.processLiveCourseError)
      // 關閉載入中
      this.$root.closeLoading()
    },
    // 處理返回直播請求成功的資料
    processLiveCourseSuccess (_response) {
      // 處理異常資料
      if (_response.body.error_code !== 0 && _response.body.error_code !== 3001) {
        return this.processRegCourseError(_response)
      }

      // 更新課程資料
      this.reLoadCourseList()

      if (this.process_course.is_started) {
        // 進入直播間
        this.$root.common.goLive(this.course_id)
      } else {
        this.$root.openOptionView({
          title: "預約成功",
          text_1: '課程已經預約成功',
          text_2: '請在課程開始時進入直播間哦！',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
      }
    },
    // 處理返回直播請求失敗的資料
    processLiveCourseError (_error) {
      this.$root.openOptionView({
        title: "發生問題",
        text_1: _error.body.message,
        text_2: '',
        course_text: '',
        option_1: "瞭解",
        option_2: '',
        option_1_fun: false,
        option_2_fun: false
      })
    },
    // 前往會議室
    goMeet (_courseID) {
      if (!this.course.is_started) {
        return this.$root.openOptionView({
          title: "尚未開始",
          text_1: '課程尚未開始哦！',
          text_2: '請在課程開始時進入會議室哦！',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
      }
      this.$root.common.goMeet(0, _courseID)
    },
    // 前往直播
    goLive (_courseID) {
      if (!this.course.is_started) {
        return this.$root.openOptionView({
          title: "尚未開始",
          text_1: '課程尚未開始哦！',
          text_2: '請在課程開始時進入直播間哦！',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
      }
      this.$root.common.goLive(_courseID)
    },
    reLoadCourseList () {
      this.$emit('reLoadCourseList')
    }
  }
}
</script>
<style lang="sass" scoped>
.bg-purple
  background: #cceafb
.bg-green
  background: #e7bbfc
.bg-orange
  background: #fadbe8
</style>
