<template lang="pug">
//- 返回頂端按鈕
.go-top-button(:class="{ active: boolActive }", @click="click")
  .inner
    i.fa.fa-chevron-up
</template>
<script>
import $ from 'jquery'
export default {
  name: 'GoTopButton',
  props: {
  },
  components: {},
  data () {
    return {
      intScrollTop: 0 // 畫面滾動了多少
    };
  },
  mounted () {
    // 監聽畫面 scroll
    window.addEventListener('scroll', this.handleScroll);
  },
  computed: {
    boolActive () {
      return this.intScrollTop > 300
    }
  },
  methods: {
    // 監聽畫面 scroll
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.intScrollTop = scrollTop
    },
    // 回到頂端
    click () {
      $('html, body').animate({
        scrollTop: 0
      }, 600)
    }
  }
}
</script>
