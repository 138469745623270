<template lang="pug">
//- 側邊選單
.side-menu-bar
  //- logo
  a(href="/")
    img.logo-img(src="@/assets/image/logo.png")

  //- 學員info
  .stu-info
    .info-photo
      img(v-if="$root.user.image", :src="$root.site_path + $root.user.image")
      img(v-else, src="@/assets/images/male@3x.png")
    .info-name
      span {{ $root.user.name }}

  //- menu
  .menu-area
    .menu-item.align-items-center.flex-nowrap(
      v-for="(menu, index) in $root.arrMenuNav",
      :class="$root.$route.name === menu.meta ? 'menu-item-active' : ''",
      @click="clickMenuOption(menu.meta, index, menu.type)"
    )
      .menu-item-img(
        v-if="menu.id === 'home'",
        :class="$root.$route.name === 'home' ? 'menu-img-active' : ''"
      )
        //- 我的課程
        .img-box(v-if="menu.id === 'home'")
          img(
            v-if="$root.$route.name !== menu.meta",
            src="@/assets/images/menu-1-off.png"
          )
          img(v-else, src="@/assets/images/menu-1-on.png")
      .menu-item-img(
        v-if="menu.id === 'scheduled'",
        :class="$root.$route.name === 'scheduled' ? 'menu-img-active' : ''"
      )
        //- 課程管理
        .img-box(v-if="menu.id === 'scheduled'")
          img(
            v-if="$root.$route.name !== menu.meta",
            src="@/assets/images/menu-2-off.png"
          )
          img(v-else, src="@/assets/images/menu-2-on.png")
      .menu-item-img(
        v-if="menu.id === 'courses'",
        :class="$root.$route.name === 'courses' ? 'menu-img-active' : ''"
      )
        //- 團班
        .img-box(v-if="menu.id === 'courses'")
          img(
            v-if="$root.$route.name !== menu.meta",
            src="@/assets/images/menu-2-off.png"
          )
          img(v-else, src="@/assets/images/menu-2-on.png")
      .menu-item-img(
        v-if="menu.id === 'history'",
        :class="$root.$route.name === 'history' ? 'menu-img-active' : ''"
      )
        //- 個教
        .img-box(v-if="menu.id === 'history'")
          img(
            v-if="$root.$route.name !== menu.meta",
            src="@/assets/images/menu-3-off.png"
          )
          img(v-else, src="@/assets/images/menu-1-on.png")

      .menu-item-img(
        v-if="menu.id === 'setting'",
        :class="$root.$route.name === 'setting' ? 'menu-img-active' : ''"
      )
        //- 直播再現
        .img-box(v-if="menu.id === 'setting'")
          img.live-img(
            v-if="$root.$route.name !== menu.meta",
            src="@/assets/images/menu-5-off.png"
          )
          img.live-img(v-else, src="@/assets/images/menu-5-on.png")

      .menu-item-img-sp(
        v-if="menu.id === 'bills'",
        :class="$root.$route.name === 'bills' ? 'menu-img-active' : ''"
      )
        //- 新生
        .img-box(v-if="menu.id === 'bills'")
          img.sp(
            v-if="$root.$route.name !== menu.meta",
            src="@/assets/images/menu-new-g.png"
          )
          img.sp(v-else, src="@/assets/images/menu-new-b.png")

      .menu-item-img(
        v-if="menu.id === 'buy'",
        :class="$root.$route.name === 'buy' ? 'menu-img-active' : ''"
      )
        //- 個資
        .img-box(v-if="menu.id === 'buy'")
          img(
            v-if="$root.$route.name !== menu.meta",
            src="@/assets/images/menu-4-off.png"
          )
          img(v-else, src="@/assets/images/menu-4-on.png")

      .menu-item-img(
        v-if="menu.id === 'profile'",
        :class="$root.$route.name === 'profile' ? 'menu-img-active' : ''"
      )
        //- 個資
        .img-box(v-if="menu.id === 'profile'")
          img(
            v-if="$root.$route.name !== menu.meta",
            src="@/assets/images/menu-4-off.png"
          )
          img(v-else, src="@/assets/images/menu-4-on.png")

      .menu-item-text
        span {{ menu.title }}
      .clearfix

  //- 登出按鈕
  .logout-box
    .space
    .logout-item(@click="logOut")
      .logout-item-img
        img(src="@/assets/images/log-out@3x.png")
      .logout-item-text
        span 登出帳戶
</template>
<script>
export default {
  name: 'homeFooter',
  components: {

  },
  data () {
    return {

    };
  },
  methods: {
    clickMenuOption (_meta, _index, _type = 1) {
      var vm = this
      this.$root.arrMenuNav.forEach(function (_menu, _menuIndex) {
        vm.$root.arrMenuNav[_menuIndex].status = false
      })
      this.$root.arrMenuNav[_index].status = true
      // this.$router.push({ name: _meta })
      this.$root.goPage(_meta, _type)
    },
    // 請求登出作業
    logOut () {
      // 執行登出作業
      this.$root.apis.logout()
      // 前往首頁
      setTimeout(() => { this.$root.goPage("/", 2) }, 250)
    }
  }
}
</script>

<style lang="sass" scoped>
.stu-info
  .info-photo
    img
      border-radius: 100%
      width: 100%
</style>