<template lang="pug">
.normal-alert-view
  .main-title {{ $root.option_view.title }}
  .main-text 
    .text {{ $root.option_view.text_1 }}
      span.bl {{ $root.option_view.course_text }}
      span {{ $root.option_view.text_2 }}
  .main-text-ps(v-if="$root.option_view.text_3")
    .text {{ $root.option_view.text_3 }}
  .button-box
    .button-item(@click="clickOption1()") {{ $root.option_view.option_1 }}
    .button-item-cancel(@click="clickOption2()") {{ $root.option_view.option_2 }}
    .clearfix
</template>
<script>
// import $ from 'jquery'
export default {
  name: '',
  props: [],
  components: {},
  data () {
    return {

    };
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    // == 處理第一個按鈕方法 ==
    clickOption1 () {
      // -- 執行處理方法
      if (typeof this.$root.option_view.option_1_fun === 'function') {
        this.$root.option_view.option_1_fun()
      }
      // -- 處理關閉警告視窗
      this.closeOptionView()
    },

    // == 處理第二個按鈕方法 ==
    clickOption2 () {
      // -- 執行處理方法
      if (typeof this.$root.option_view.option_2_fun === 'function') {
        this.$root.option_view.option_2_fun()
      }
      // -- 處理關閉警告視窗
      this.closeOptionView()
    },
    closeOptionView () {
      this.$root.isAlert = false
      this.$root.option_view.option_1_fun = false
      this.$root.option_view.option_2_fun = false
      this.$root.option_view.title = ''
      this.$root.option_view.text_1 = ''
      this.$root.option_view.text_2 = ''
      this.$root.option_view.text_3 = ''
      this.$root.option_view.course_text = ''
      this.$root.option_view.option_1 = ''
      this.$root.option_view.option_2 = ''

    }
  }
}
</script>
<style lang="sass" scoped>
.main-title
  font-size: 1.3rem
  font-weight: 500
  color: #191d35
  line-height: 2rem
  text-align: center
  margin: 2rem 0 1rem 0
.main-text
  margin-bottom: 1rem
  line-height: 1.6rem
  text-align: center
  margin: 0 .4rem 1rem .4rem
  .text
    color: #828fa9
    font-size: .9rem
    .bl
      color: #2e50ff
      margin-left: .2rem
      font-weight: 500

.main-text-ps
  margin-bottom: 1rem
  line-height: 1.6rem
  text-align: center
  margin: 0 .4rem 1rem .4rem
  .text
    color: #a00000
    font-size: .9rem
    font-weight: 500
    text-align: left

.button-box
  text-align: center
  margin-top: .5rem
  .button-item
    cursor: pointer
    font-size: 1rem
    font-weight: 500
    color: #191d35
    line-height: 4rem
    border-top: 1px solid #c7c7c7
    &:hover
      opacity: .8
    &:active
      opacity: .6
  .button-item-cancel
    cursor: pointer
    font-size: 1rem
    font-weight: 500
    color: #828fa9
    line-height: 4rem
    border-top: 1px solid #c7c7c7
    &:hover
      opacity: .8
    &:active
      opacity: .6
</style>
