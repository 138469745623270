<template lang="pug">
.profile-tab-one-view
  .head-photo
    .head-photo-img
      img(v-if="userData.image", :src="$root.site_path + userData.image")
      img(v-else, src="@/assets/images/male@3x.png")
    //- img.camera-img.click-cursor(src="@/assets/images/camera.png")
  .profile-form.form
    .profile-form-sm.mr-10
      //- 姓名
      .form-group
        .form-group-title 姓名
        input.form-group-input(
          type="text",
          v-model="userData.userName",
          placeholder="請輸入您的姓名"
        )

      //- 暱稱
      .form-group
        .form-group-title 暱稱
        input.form-group-input(
          type="text",
          v-model="userData.userNickName",
          placeholder="請輸入您的暱稱"
        )

      //- 電子郵件
      .form-group
        .form-group-title 電子郵件(帳戶)
        input.form-group-input(
          type="text",
          v-model="userData.userEmail",
          placeholder="請輸入您的Email"
        )

      //- 所在區域
      .form-group
        .form-group-title 所在區域
        .address-select-box
          select.address-box(
            v-model="userData.userCity",
            v-bind:class="userData.userCity !== 0 && userData.city !== '0' ? 'color-blue' : 'color-gray'"
          )
            option(value=0) 請選擇您的所在區域
            option(v-for="(city, index) in citys", :value="city.id") {{ city.city }}
          img.downarrow-img(src="@/assets/images/triangle@3x.png")

      //- 生日
      .form-group
        .form-group-title 生日
        .birthday-select-box
          input.birthday-select(
            type="date",
            v-model="userData.userBirthday",
            placeholder="請選擇您的生日"
          )

    .profile-form-sm
      //- 性別
      .form-group
        .form-group-title 性別
        .form-sex
          .form-sex-male.click-cursor(
            :class="userData.userGender === '1' ? 'btn-active' : 'btn-noactive'",
            @click="changeSex('1')"
          ) 男性
          .form-sex-female.click-cursor(
            :class="userData.userGender === '2' ? 'btn-active' : 'btn-noactive'",
            @click="changeSex('2')"
          ) 女性

      //- 手機號碼
      .form-group
        .form-group-title 手機號碼
        .form-phone-box
          //- select.phoneselect(v-model="$root.country_code")
            option(v-for="(code, index) in $root.country_codes", :value="code") {{ code }}
          input.phoneinput(
            type="text",
            placeholder="請輸入您的行動電話",
            v-model="cacheCPhone"
          )
        .send-code(v-if="cacheCPhone !== userData.userCPhone")
          .form-send-btn.click-cursor.btn-active(
            v-if="last_time >= 91 || last_time === 0",
            @click="sendPhoneMessage"
          ) 發送驗證碼
          .form-send-btn.btn-noactive(v-else) {{ last_time }}s
        .clearfix

      //- 驗證碼
      .form-group(v-if="cacheCPhone !== userData.userCPhone")
        .form-group-title 驗證碼
        input.form-group-input.maxw250(
          type="text",
          placeholder="請輸入驗證碼",
          v-model="phoneCode"
        )
        .form-send-btn.click-cursor(@click="confirmPhoneCode") 驗證
        .clearfix
    .clearfix

    .profile-hr

    .form-title.profile-form-title 修改密碼
    .profile-form-sm.mr-10
      //- 新密碼
      .form-group
        .form-group-title 新密碼
        input.form-group-input(
          type="text",
          v-model="userData.userNewPwd",
          placeholder="請輸入您的新密碼(無修改請留空)"
        )

    .profile-form-sm.mr-10
      //- 確認新密碼
      .form-group
        .form-group-title 再次確認密碼
        input.form-group-input(
          type="text",
          v-model="userData.userNewPwd2",
          placeholder="請確認您的新密碼(無修改請留空)"
        )
    .profile-form-sm.mr-10
    .clearfix
  .clearfix
  .save-btn.click-cursor.btn-active(@click="saveProfile()") 保存修改
  #send-messenger-button
</template>

<script>
import firebase from 'firebase'
export default {
  props: {
  },
  data () {
    return {
      phoneCode: '',
      cacheCPhone: '',
      verify_code: '',
      last_time: 91,
      // 個人資料
      userData: {},
      //- 密碼
      new_password: '',
      confirm_password: '',
      citys: [
        {
          "id": 1,
          "city": "臺北市"
        },
        {
          "id": 2,
          "city": "新北市"
        },
        {
          "id": 3,
          "city": "桃園市"
        },
        {
          "id": 4,
          "city": "臺中市"
        },
        {
          "id": 5,
          "city": "臺南市"
        },
        {
          "id": 6,
          "city": "高雄市"
        },
        {
          "id": 7,
          "city": "新竹縣"
        },
        {
          "id": 8,
          "city": "苗栗縣"
        },
        {
          "id": 9,
          "city": "彰化縣"
        },
        {
          "id": 10,
          "city": "南投縣"
        },
        {
          "id": 11,
          "city": "雲林縣"
        },
        {
          "id": 12,
          "city": "嘉義縣"
        },
        {
          "id": 13,
          "city": "屏東縣"
        },
        {
          "id": 14,
          "city": "宜蘭縣"
        },
        {
          "id": 15,
          "city": "花蓮縣"
        },
        {
          "id": 16,
          "city": "臺東縣"
        },
        {
          "id": 17,
          "city": "澎湖縣"
        },
        {
          "id": 18,
          "city": "金門縣"
        },
        {
          "id": 19,
          "city": "連江縣"
        },
        {
          "id": 20,
          "city": "基隆市"
        },
        {
          "id": 21,
          "city": "新竹市"
        },
        {
          "id": 22,
          "city": "嘉義市"
        },
        {
          "id": 23,
          "city": "其他地方"
        }
      ]
    }
  },
  watch: {
  },
  created () {
    this.getStudentDetail()
  },
  methods: {
    // 請求獲取學員資料
    async getStudentDetail () {
      await this.$root.apis.getStudentDetail(this.processGetStudentDetailSuccess, this.processGetStudentDetailError)
    },
    // 處理獲取學員資料成功
    processGetStudentDetailSuccess (_response) {
      if (_response.body.error_code !== 0) {
        return this.processGetStudentDetailError(_response)
      }

      // 處理獲得的學員資料
      this.userData = _response.body.data
      // 快取化目前的行動號碼
      this.cacheCPhone = this.userData.userCPhone
      // 載入 FireBase
      this.loadFireBase()
    },
    // 處理獲取學員資料失敗
    processGetStudentDetailError () {
    },
    // Load FireBase
    loadFireBase () {
      // 使用 FireBase Phone Auth
      console.log('--> 載入 FireBase Phone Auth...')
      firebase.auth().languageCode = 'zhTw'
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('send-messenger-button', {
        'size': 'invisible',
        'callback': function () {
        }
      })
    },
    // 請求發送簡訊
    async sendPhoneMessage () {
      // 清空驗證碼欄位
      this.phoneCode = ''
      // 組合電話號碼
      let phoneNumber = await this.$root.common.getPhoneCode('+886', this.cacheCPhone)
      this.$root.cellphoneStep = phoneNumber
      console.log('等待驗證的電話號碼: ' + phoneNumber)
      // 請求發送驗證簡訊
      await this.$root.sendPhoneNumberCode(phoneNumber, this.openConfirmCodeInput)
      // 開始倒數計時
      this.$options.interval = setInterval(this.runbyeverytime, 1000)
    },
    // 請求驗證簡訊編號
    confirmPhoneCode () {
      this.$root.confirmPhoneNumberCode(this.$root.cellphoneStep, this.phoneCode, this.phoneNumberConfirm)
    },
    // 當電話號碼被驗證成功
    async phoneNumberConfirm (_phoneNumber) {
      // 更新電話號碼
      this.cacheCPhone = _phoneNumber
      // 清空暫存器
      this.phoneCode = ''
      this.$root.cellphoneStep = ''

      // 請求更新學員資料
      await this.saveProfile(4, _phoneNumber)
    },
    // 變更性別
    changeSex (_num) {
      this.userData.userGender = _num
    },
    // 倒數計時功能
    runbyeverytime () {
      // 處理倒數計時秒數 90
      if (this.last_time >= 1) {
        // console.log('--- runbyeverytime ---')
        this.last_time = this.last_time - 1
        console.log('--> time: ' + this.last_time)
      }
    },
    // 請求更新學員資料
    async saveProfile (_type = 1, _data = '') {
      var formData

      if (_type === 1) {
        formData = this.userData
        formData.formType = 1
        // ===========================
        if (formData.userNewPwd !== '' || formData.userNewPwd2 !== '') {
          if (formData.userNewPwd !== formData.userNewPwd2) {
            this.$root.openOptionView({ title: "更新學員資料", text_1: "需要變更的密碼不相同喔", option_1: "瞭解", })
            return false
          }
          // =========================================
          formData.password = formData.userNewPwd
          // =========================================
        }
      }

      if (_type === 2) {
        formData = {
          formType: 2,
          image: _data
        }
      }

      if (_type === 4) {
        formData = {
          formType: 4,
          userCPhone: _data
        }
      }

      await this.$root.apis.putStudentDetail(formData, this.processPutStudentDetailSuccess, this.processPutStudentDetailError)
    },
    // 處理更新學員資料成功
    async processPutStudentDetailSuccess (_response) {
      if (_response.body.error_code !== 0) {
        return this.processPutStudentDetailError(_response)
      }

      // 顯示通知資訊
      await this.$root.openOptionView({ title: "更新學員資料", text_1: "資料更新成功", option_1: "瞭解", })

      // 重新獲取學員資料
      await this.getStudentDetail()
      await this.$root.apis.getSystemInfo()
    },
    // 處理更新學員資料失敗
    processPutStudentDetailError (_error) {
      // 顯示通知資訊
      this.$root.openOptionView({ title: "更新學員資料", text_1: _error.body.message, option_1: "瞭解", })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
