<template lang="pug">
.home-video
  .row
    .col-2
    .col-8
      //- 大視窗
      .home-video-main
        .row
          .item.col-lg-6.col-md-6.col-sm-12(v-for="nav in video_nav_data")
            img.nav-img(:src="nav.src")
            img.nav-play.cursor-pointer(
              src="@/assets/image/sm-play.png",
              @click="$root.openVideo(nav.video_id)"
            )
    .col-2
  a.area-btn.button.button.btn-default.btn-sm.btn-width-auto(
    @click="$root.goPage('student-login')"
  ) 開始學習
</template>
<script>
// import $ from 'jquery'

export default {
  name: 'homeVideoArea',
  components: {

  },
  props: {},
  data () {
    return {
      video_nav_data: [
        {
          src: require('@/assets/image/banner-1.jpg'),
          video_id: 'ZPm7OCgbzlM'
        },
        {
          src: require('@/assets/image/banner-2.png'),
          video_id: 'xG0gPD7f8sg'
        },
        {
          src: require('@/assets/image/banner-3.jpg'),
          video_id: '8-JCLWF7Guc'
        },
        {
          src: require('@/assets/image/banner-4.jpg'),
          video_id: '5sjJ7U5Egwk'
        },
        {
          src: require('@/assets/image/banner-5.png'),
          video_id: 'M9ek1sNCcks'
        },
        {
          src: require('@/assets/image/banner-6.jpg'),
          video_id: 'iAghd_HLh6A'
        }
      ]
    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
