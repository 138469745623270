<template lang="pug">
//- header - nav 選單 - 桌機版
.container-fluid.v2
  nav.nav-desktop.row.align-items-center.flex-nowrap
    //- logo
    .col.text-align-left.flex-shrink-0
      .nav-logo.float-left.cursor-pointer( @click="$root.goPage('/', 2)")
        img(src="@/assets/image/logo.png")
      .clearfix

    //- 頁籤
    .col-auto.flex-grow-0.flex-shrink-0.link-btn(
      v-for="nav in arrHeaderNav",
      v-if="nav.status && !$root.hide_page_status"
    )
      a.cursor-pointer.nav-link(
        @click="clickNav(nav.meta, nav.type)",
        v-bind:class="$root.nav_meta === nav.meta ? 'active-link' : ''"
      ) {{ nav.title }}

    //- 學習按鈕
    .col.text-align-right.flex-shrink-0.flex-grow-0
      .login-header.login-before(
        v-if="!$root.login_status",
        v-bind:class="!$root.hide_page_status ? 'header-btn-btm' : ''"
      )
        a.button.btn-sm.btn-width-auto.header-btn(
          @click="$root.goPage('login')"
        )
          span 開始學習
</template>
<script>
export default {
  name: 'HeaderNavDeskTop',
  components: {},
  props: {
    arrHeaderNav: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
    };
  },
  methods: {
    clickNav (meta, type) {
      this.$emit('clickNav', meta, type);
    },
    clickStart () {
      this.$emit('clickStart');
    },
  }
}
</script>
