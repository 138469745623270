<template lang="pug">
//- header
header.d-flex.home-header-v2
  //- 桌機版 nav
  HeaderNavDesktop.d-lg-block.d-md-block.d-sm-block.d-none(
    :arrHeaderNav="arrHeaderNav",
    @clickNav="clickNav",
    @clickStart="clickStart"
  )
  //- 手機版 nav
  HeaderNavMobile.d-lg-none.d-md-none.d-sm-none(
    :arrHeaderNav="arrHeaderNav",
    @clickNav="clickNav",
    @clickStart="clickStart"
  )
</template>
<script>
import HeaderNavDesktop from '@/common/header-nav-desktop-v2'
import HeaderNavMobile from '@/common/header-nav-mobile'
import $ from 'jquery'
export default {
  name: 'homeHeader',
  components: {
    HeaderNavDesktop,
    HeaderNavMobile
  },
  props: {},
  data () {
    return {
      arrHeaderNav: this.$root.arrHeaderNav,

    };
  },
  computed: {

  },
  mounted () {
    // 監聽畫面 scroll
    console.log(1);
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    // 監聽畫面 scroll
    handleScroll () {
      console.log(1);
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 1) {
        $('.home-header-v2').css('background-color', 'rgba(47, 80, 255, .5)')
      }
    },
    clickNav (_meta, _type = 1) {
      this.$root.nav_meta = _meta
      this.$root.goPage(_meta, _type)
      // this.$router.push({ name: _meta })
    },
    clickStart () {
      this.$root.goPage('student-login')
    },
  }
}
</script>