<template lang="pug">
//- 交易紀錄
.scheduled-index.page-Index
  //- 內容 TOP (內有 搜尋bar 及 通知區塊 )
  ContentTop

  .scheduled-top
    .scheduled-title 待排課程
  .scheduled-content
    .add-scheduled-btn.click-cursor(@click="$root.goPage('new_scheduled', 1)") 新增排課
    .scheduled-list-table-scroll
      table.scheduled-list-table
        thead
          tr.text-align-center
            th 項次
            th 課程名稱
            th 課程堂數
            th 建立時間
            th 互動人數
            th 直播人數
            th 語言
            th 師資
            th 使用教材
            th 排課狀態
            th
        tbody
          tr.text-align-center(v-if="course.length === 0")
            td(colspan="11") 目前無資料
          tr.text-align-center(v-else v-for="(item, index) in course")
            td {{ index + 1 }}
            td {{ item.name }}
            td {{ item.sub_course_count }}
            td {{ item.created_time }}
            td {{ item.room_limit }}
            td {{ item.live_limit }}
            td 
              span(v-if="item.language === 'en'") 美語
              span(v-if="item.language === 'ja'") 日語
              span(v-if="item.language === 'ko'") 韓語
            td 
              span(v-if="item.teacher_type === '1'") 中師
              span(v-if="item.teacher_type === '2'") 母語外師
            td 
              span(v-if="item.material_type === '1'") 地球村特許教材
              span(v-if="item.material_type === '2'") 自訂教材
            td 
              span(v-if="item.status === '0'") 待支付
              span(v-else-if="item.status == '1'") 待審查
              span(v-else-if="item.status == '2'") 待排課
              span(v-else-if="item.status == '3'") 待上課
              span(v-else-if="item.status == '4'") 已取消
              span(v-else-if="item.status == '5'") 已完成

            td.scheduled-table-btns
              .scheduled-item-btn_1.click-cursor(@click="$root.goPage('scheduled_detail',1,{ id: item.id })") 查看詳情
              .scheduled-item-btn_2.click-cursor(v-if="item.status <= 2") 取消排課

  .list-bottom(v-if="scheduled_data.length !== 0")
    nav
      ul.pagination.pagination-rounded
        li.disabled(v-if="page > 1")
          a(@click="getBusinessMainCourseList(page, 1)", aria-label="Previous")
            i.fa.fa-chevron-left.first

        li(
          v-for="pre_page in totalPage",
          v-if="pre_page < page + 4 && pre_page > page - 4",
          :class="page == pre_page ? 'active' : ''"
        )
          a(@click="getBusinessMainCourseList(pre_page)")
            | {{ pre_page }}

        li(v-if="page < totalPage")
          a(@click="getBusinessMainCourseList(page, 2)", aria-label="Next")
            i.fa.fa-chevron-right.last
</template>

<script>
import $ from 'jquery'
import ContentTop from '@/components/content-top/content-top'

export default {
  name: 'MainAccountCourseList',
  components: {
    ContentTop
  },
  data () {
    return {
      course: [],
      scheduled_data: [],
      page: 1,
      totalPage: 0,
    };
  },
  async created () {
    // 關閉載入中畫面
    await this.$root.closeLoading()
    // 獲得課程相關資料
    this.getBusinessMainCourseList()
  },
  mounted () { },
  computed: {},
  methods: {
    //- 回至頂部
    goTop () {
      $('.profile-index').animate({
        scrollTop: 0
      }, 200)
    },
    //- 取得主帳號下的所有課程
    getBusinessMainCourseList (_page = '', _opt = '') {
      if (_page) {
        this.page = (_page)
      }
      if (_opt === 1) {
        this.page = this.page - 1
      }
      if (_opt === 2) {
        this.page = this.page + 1
      }

      let vm = this
      this.$root.apis.getBusinessMainCourseList({
        status: [1, 2, 3]
      }, function (_response) {
        let response = _response.body.data
        vm.course = response.items
        vm.totalPage = response.pages
        vm.goTop()
      })
    }
  }
}
</script>

<style lang="sass">
</style>
