<template lang="pug">
//- 學生登入
.student-login-index.page-index
  Header
  .login-main
    PageTop(page="login")
    .login-form-view
      .login-form.form
        .form-title 地球村專屬課
        .form-text 歡迎使用課務系統，請登入您的帳戶
        //- 手機號碼登入
        .way-2
          //- 手機號碼input
          .form-group
            .form-group-title 手機號碼
            //- 大視窗
            .d-lg-block.d-md-block.d-sm-block.d-none
              .phonecheck-input-lg
                select.phoneselect.padding-none.float-left(
                  v-model="$root.country_code"
                )
                  option(
                    v-for="(code, index) in $root.country_codes",
                    :value="code"
                  ) {{ code }}

                input.phoneinput.float-left(
                  type="tel",
                  placeholder="輸入您的手機號碼",
                  v-model="login_cellphone",
                  spellcheck="false"
                )
                .clearfix

              .second-btn-lg(v-if="last_time <= 90 && last_time >= 1") {{ last_time }}
              .second-btn-lg(
                v-else-if="last_time < 1",
                @click="reciprocalTime()"
              ) 重新發送
              .send-btn-lg(v-else, @click="reciprocalTime()") 發送驗證碼
            .clearfix

            //- 小視窗
            .d-lg-none.d-md-none.d-sm-none.d-block
              .phonecheck-input-sm
                select.phoneselect.padding-none.float-left(
                  v-model="$root.country_code"
                )
                  option(
                    v-for="(code, index) in $root.country_codes",
                    :value="code"
                  ) {{ code }}

                input.phoneinput.float-left(
                  type="tel",
                  placeholder="輸入您的手機號碼",
                  v-model="login_cellphone",
                  spellcheck="false"
                )
                .clearfix
              .second-btn-sm(v-if="last_time <= 90 && last_time >= 1") {{ last_time }}
              .second-btn-sm(
                v-else-if="last_time < 1",
                @click="reciprocalTime()"
              ) 重新發送
              .send-btn-sm(v-else, @click="reciprocalTime()") 發送驗證碼
            .clearfix

          //- 驗證碼
          .form-group
            .form-group-title 驗證碼
            input.form-group-input(
              type="number",
              placeholder="請輸入6位驗證碼",
              v-model="login_verinput"
            )

          //- 按鈕
          .form-group
            .form-group-button.button(@click="confirmPhoneCode")
              span 驗證

          #send-messenger-button
  Footer
</template>
<script>
import Header from '@/components/header_v2'
import Footer from '@/components/footer_v2'
import PageTop from '@/common/page-top'
import firebase from 'firebase'

export default {
  name: 'studetnLoginIndex',
  components: {
    Header,
    Footer,
    PageTop
  },
  data () {
    return {
      login_cellphone: '',
      login_verinput: '',
      last_time: 91,
    };
  },
  async created () {
    // 如果已經登入，就送進去內頁
    if (this.$root.user.id) {
      return this.$root.confirmIsLogin()
    }

    setTimeout(() => { this.loadFireBase() }, 250)
  },
  methods: {
    reciprocalTime () {
      // 處理倒數計時相關功能
      this.last_time = 90
      this.$options.interval = setInterval(this.runbyeverytime, 1000)
      // 開始請求發送驗證碼
      this.sendPhoneMessage()
    },
    runbyeverytime () {
      // 處理倒數計時秒數 90
      if (this.$data.last_time >= 1) {
        // console.log('--- runbyeverytime ---')
        this.$data.last_time = this.$data.last_time - 1
        // console.log('--> time: ' + this.$data.last_time)
      }
    },
    changeWay (_way) {
      this.$emit('changeWay', _way);
    },
    // Load FireBase
    loadFireBase () {
      // 使用 FireBase Phone Auth
      console.log('--> 載入 FireBase Phone Auth...')
      firebase.auth().languageCode = 'zhTw'
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('send-messenger-button', {
        'size': 'invisible',
        'callback': function () { }
      })
    },
    // 請求發送簡訊
    sendPhoneMessage () {
      // 清空驗證碼欄位
      this.phoneCode = ''
      // 組合電話號碼
      let phoneNumber = this.$root.common.getPhoneCode(this.$root.country_code, this.login_cellphone)
      this.$root.cellphoneStep = phoneNumber
      console.log('等待驗證的電話號碼: ' + phoneNumber)
      // 確認是否在白名單中
      this.$root.apis.isWhileListByCellPhone(phoneNumber, function (_response) {
        let response = _response.body.data
        // 在白名單，跳到下頁
        if (response) {
          return
        }
        // 不在白名單，略過處理
        else {
          // 請求發送驗證簡訊
          this.$root.sendPhoneNumberCode(phoneNumber)
        }
      })
    },
    // 請求驗證簡訊編號
    confirmPhoneCode () {
      // 開啟載入中
      let vm = this
      // 獲取處理後的電話號碼
      let phoneNumber = this.$root.common.getPhoneCode(this.$root.country_code, this.login_cellphone)
      // 請求進行白名單驗證，如果驗證失敗則尋求其他方式
      this.$root.apis.authStudentCellPhone(phoneNumber, this.login_verinput, this.phoneNumberConfirm, function (_response) {
        let response = _response.body
        console.log('=> 白名單驗證結果: ', response)

        // 行動電話不在白名單之中
        if (response.error_code == 1404) {
          return vm.$root.confirmPhoneNumberCode(vm.$root.cellphoneStep, vm.login_verinput, vm.phoneNumberConfirm)
        }

        // 白名單驗證碼驗證失敗
        if (response.error_code == 1400) {
          return vm.$root.openOptionView({
            title: '行動號碼驗證',
            text_1: '您輸入的驗證碼與白名單紀錄中的不符',
            text_2: '請稍候再試',
            option_1: '瞭解'
          })
        }
      })
    },
    // 當電話號碼被驗證成功
    async phoneNumberConfirm () {
      // 請求進行登入操作
      let phoneNumber = this.$root.common.getPhoneCode(this.$root.country_code, this.login_cellphone)
      await this.$root.apis.usePlatformByPhoneNumber(phoneNumber, this.loginSuccess, this.loginError)
    },
    // 處理登入成功
    async loginSuccess (_response) {
      if (_response.body.error_code !== 0) {
        this.loginError(_response)
        return
      }
      // 處理登入成功的操作
      await this.$root.apis.getSystemInfo()
      // 處理登入後的資訊
      await this.$root.confirmIsLogin()
    },
    // 處理登入失敗
    async loginError (_error) {
      this.$root.openOptionView({
        title: "登入發生問題",
        text_1: _error.body.message,
        option_1: "瞭解",
      })
    }
  },

  computed: {
  }
}
</script>
<style lang="sass">
</style>
