<template lang="pug">
.date-view
  .date-top
    .date-text(v-if="use_date_year") {{ use_date_year }}.{{ use_date_month }}
    //- 預約按鈕
    //- .reservation-btn
      span 預約課程 ＋

    //- 表格開關按鈕
    .table-turn-btn(
      :class="date_turn ? 'turn-btn-on' : ''",
      @click="turnDateTable()"
    )
      img(v-if="!date_turn", src="@/assets/images/date-g.png")
      img(v-else, src="@/assets/images/date-b.png")

    //- 今天按鈕
    .today-btn(
      v-bind:class="[date_filter === now_day ? 'today-btn-on' : '']",
      @click="clickDate(now_day)"
    ) 今天
    .clearfix
  table.date-table
    tr
      th(v-bind:class="[use_date_week === 0 ? 'th-active' : '']") 日
      th(v-bind:class="[use_date_week === 1 ? 'th-active' : '']") 一
      th(v-bind:class="[use_date_week === 2 ? 'th-active' : '']") 二
      th(v-bind:class="[use_date_week === 3 ? 'th-active' : '']") 三
      th(v-bind:class="[use_date_week === 4 ? 'th-active' : '']") 四
      th(v-bind:class="[use_date_week === 5 ? 'th-active' : '']") 五
      th(v-bind:class="[use_date_week === 6 ? 'th-active' : '']") 六

    tr(v-for="(week, index) in weeks.slice(0, this.date_data_stataus)")
      td(@click="clickDate(week[0].date)")
        span(:class="[date_filter === week[0].date ? 'span-active' : '']") {{ week[0].day }}
      td(@click="clickDate(week[1].date)")
        span(:class="[date_filter === week[1].date ? 'span-active' : '']") {{ week[1].day }}
      td(@click="clickDate(week[2].date)")
        span(:class="[date_filter === week[2].date ? 'span-active' : '']") {{ week[2].day }}
      td(@click="clickDate(week[3].date)")
        span(:class="[date_filter === week[3].date ? 'span-active' : '']") {{ week[3].day }}
      td(@click="clickDate(week[4].date)")
        span(:class="[date_filter === week[4].date ? 'span-active' : '']") {{ week[4].day }}
      td(@click="clickDate(week[5].date)")
        span(:class="[date_filter === week[5].date ? 'span-active' : '']") {{ week[5].day }}
      td(@click="clickDate(week[6].date)")
        span(:class="[date_filter === week[6].date ? 'span-active' : '']") {{ week[6].day }}
</template>

<script>
export default {
  props: [
    'use_type',
    'date_filter',
    'date_turn',
  ],
  data () {
    return {
      // 周曆陣列
      weeks: [],
      //- 顯示陣列數
      date_data_stataus: 1,
      //- 點擊的日期
      date_click: 0,
      use_date_week: false,
      use_date_year: false,
      use_date_month: false,
      //- 今天日期
      now_week: false,
      now_day: false
    }
  },
  watch: {
    // 當日期被改變時
    'date_filter' (_date) {
      // 獲取指定日期的 Timestamp
      let timestamp = new Date(_date).getTime() / 1000
      // 更新選擇的週數
      this.use_date_year = this.$root.common.getNowTime('year', timestamp)
      this.use_date_week = this.$root.common.getNowTime('week', timestamp)
      this.use_date_month = this.$root.common.getNowTime('month', timestamp)
    },
    // 當單/多週被切換時
    date_turn (_status) {
      if (_status) {
        this.$data.date_data_stataus = 3
      } else {
        this.$data.date_data_stataus = 1
      }
    }
  },
  created () {
    var vm = this
    setTimeout(() => {
      vm.processStartView()
      vm.$root.closeLoading()
    }, 50);
  },
  methods: {
    // 處理畫面初始化
    processStartView () {
      this.use_date_year = this.$root.common.getNowTime('year')
      this.use_date_week = this.$root.common.getNowTime('week')
      this.use_date_month = this.$root.common.getNowTime('month')
      // // 獲得今日日期
      let nowDate = this.$root.common.getNowTime('date')
      console.log('--> 今日日期: ', nowDate)
      this.clickDate(nowDate)
      this.now_day = nowDate
      // // 組合週曆陣列
      this.makeWeekDates()
      this.date_filter = this.$root.common.getNowTime('date')
    },
    // 組合各週日期陣列
    makeWeekDates () {
      var vm = this
      // 一日的總秒數
      let oneDaySeconds = 86400
      // 獲取今日的時間戳
      let nowTimestamp = this.$root.common.getNowTime('timestamp')
      console.log('--> 今日的時間戳: ', nowTimestamp)
      // 獲取今日的週數
      let nowWeek = this.$root.common.getNowTime('week')
      console.log('--> 今日的週數: ', nowWeek)
      // 多週陣列
      let Weeks = []

      // 是否已達到第一週週數與當日相同
      let isWeek = false
      let days = 0

      // 只顯示三週，從第一週開始跑
      for (let wcount = 0; wcount < 3; wcount++) {
        Weeks[wcount] = []
        // 只處理 7 天，從第一天開始跑
        for (let dcount = 0; dcount <= 6; dcount++) {
          // 第一週的週數與今日相同
          if (wcount === 0 && dcount === nowWeek) {
            isWeek = true
            Weeks[wcount][dcount] = {
              date: vm.$root.common.getNowTime('date', nowTimestamp),
              day: vm.$root.common.getNowTime('date-day', nowTimestamp),
              remind: false
            }
          }
          // 取得當日日期
          else if (isWeek) {
            days += 1
            let dateTimestamp = (nowTimestamp + (oneDaySeconds * days))
            Weeks[wcount][dcount] = {
              date: vm.$root.common.getNowTime('date', dateTimestamp),
              day: vm.$root.common.getNowTime('date-day', dateTimestamp),
              remind: false
            }
          }
          // 當不符合需要顯示的日期
          else {
            Weeks[wcount][dcount] = {
              date: '',
              day: '',
              remind: false
            }
          }
        }
      }

      // 更新週曆資料
      this.weeks = Weeks
    },
    // 切換單/多週顯示
    turnDateTable () {
      this.$emit('dateTurn')
    },
    // 點選了新的日期
    clickDate (_courseDate) {
      if (!_courseDate) {
        return
      }
      this.$emit('setDate', _courseDate)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
