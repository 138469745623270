<template lang="pug">
//- 排課詳情
.scheduled-detail-index.page-Index
  //- 內容 TOP (內有 搜尋bar 及 通知區塊 )
  ContentTop

  .new-scheduled-top.mb-5
    button.detail-btn(type="button",@click="$root.goPage('scheduled',1)") 回上一頁

  .new-scheduled-top
    .detail-title
      .new-scheduled-text 課程資訊 | {{ course.course_name }}
    .detail-status 
      span(v-if="course.status == '0'") 待支付
      span(v-else-if="course.status == '1'") 待審查
      span(v-else-if="course.status == '2'") 待排課
      span(v-else-if="course.status == '3'") 待上課
      span(v-else-if="course.status == '4'") 已取消
      span(v-else-if="course.status == '5'") 已完成

    //- 改由後台人員處理退課流程
    //- button.cancel-btn(type="button", v-if="course.status <= 2") 取消排課

  .scheduled-detail-content
    .scheduled-row
      .scheduled-detail-group
        .scheduled-detail-group-title 課程名稱
        .scheduled-detail-group-text {{ course.course_name }}

      .scheduled-detail-group
        .scheduled-detail-group-title 課程時長
        .scheduled-detail-group-text {{ course.course_minutes }}分鐘

      .scheduled-detail-group
        .scheduled-detail-group-title 互動人數
        .scheduled-detail-group-text {{ course.course_room_limit }}人

      .scheduled-detail-group
        .scheduled-detail-group-title 直播人數
        .scheduled-detail-group-text {{ course.course_live_limit }}人

      .scheduled-detail-group
        .scheduled-detail-group-title 語言
        .scheduled-detail-group-text 
          span(v-if="course.course_language === 'en'") 美語
          span(v-if="course.course_language === 'ja'") 日語
          span(v-if="course.course_language === 'ko'") 韓語

      .scheduled-detail-group
        .scheduled-detail-group-title 師資
        .scheduled-detail-group-text 
          span(v-if="course.course_teacher_type === '1'") 中師
          span(v-if="course.course_teacher_type === '2'") 母語外師

      .scheduled-detail-group
        .scheduled-detail-group-title 使用教材
        .scheduled-detail-group-text 
          span(v-if="course.course_material_type === '1'") 地球村特許教材
          span(v-if="course.course_material_type === '2'") 自訂教材

    .scheduled-row

      .scheduled-detail-group
        .scheduled-detail-group-title 課程時間表
        .scheduled-detail-group-text.mb-3(v-for="( item, index ) in course.course_items") 
          .course-time {{ item.course_data }} {{ item.course_time }}
          .course-status
            span(v-if="item.status == '0'") 異常課堂
            span(v-if="item.status == '1'") 等待排課
            span(v-if="item.status == '2'") 等待上課
            span(v-if="item.status == '3'") 已經上課

    .scheduled-row.student-list

      .scheduled-detail-group
        .scheduled-detail-group-title 已預約的學員
        .scheduled-detail-group-text(v-for="(student, index) in students")
          span ({{ '#' + student.course_id }})
          span  /  {{ student.name }}
          span(v-if="student.course_watchtype === 'bs-meeting'")  / (互動課程)
          span(v-if="student.course_watchtype === 'bs-live'")  / (直播課程)

</template>
<script>
import $ from 'jquery'
import ContentTop from '@/components/content-top/content-top'


export default {
  courseId: '',
  name: 'MainCourseDetail',
  components: {
    ContentTop,
  },
  data () {
    return {
      course: {},
      students: []
    };
  },
  async created () {
    // 關閉載入中畫面
    await this.$root.closeLoading()
    // 獲得課程ID
    this.courseId = this.$route.params.id
    // 獲得課程資訊
    this.GetCourseDetail()
    this.GetCourseStudents()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    //- 回至頂部
    goTop () {
      $('.profile-index').animate({
        scrollTop: 0
      }, 200)
    },
    GetCourseDetail () {
      let vm = this
      this.$root.apis.getBusinessCourseDetail(this.courseId, function (_response) {
        vm.course = _response.body.data
      })
    },
    GetCourseStudents () {
      let vm = this
      this.$root.apis.GetCourseStudents(this.courseId, function (_response) {
        vm.students = _response.body.data
      })
    }
  }
}
</script>

<style lang="css" scoped>
.mb-3 {
  margin-bottom: 15px;
}

.mb-5 {
  margin-bottom: 25px;
}

.scheduled-row.student-list {
  width: auto;
}
</style>