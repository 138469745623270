<template lang="pug">
.profile-index.page-Index
  //- 內容 TOP (內有 搜尋bar 及 通知區塊 )
  ContentTop

  .profile-top.row
    .profile-title 個人資訊
    
  
  //- 切換內容
  .profile-content
    //- 基本資料
    TabOneContent(:user="$root.user")

</template>
<script>
import $ from 'jquery'
import ContentTop from '@/components/content-top/content-top'
import TabOneContent from '@/components/profile/tab-1-profile'


export default {
  name: 'Index',
  components: {
    ContentTop,
    TabOneContent,
  
  },
  data () {
    return {
    
    };
  },
  async created () {
    // 請求進行更新學員資料
    // await this.$root.apis.getSystemInfo()
    // 關閉載入中畫面
    await this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    //- 回至頂部
    goTop () {
      $('.profile-index').animate({
        scrollTop: 0
      }, 200)
    },
  }
}
</script>
<style lang="sass">
</style>
