<template lang="pug">
//- 通知區塊
.notice-area
  //- 通知按鈕
  .notice-btn(@click="clickNoticeBtn()")
    .notice-num {{ not_read_num }}
    .notice-img
      img(src="@/assets/images/notice.png")

  //- 訊息列表
  .notice-list.animated-3.fadeIn(v-if="list_show")
    //- 通知列表上方
    .list-top
      .list-top-text(v-if="not_read_num >= 1")
        span 您有未讀{{ not_read_num }}則新訊息
      .list-top-btn.button.btn-default.btn-sm.btn-width-auto
        span 檢視全部
      .clearfix
    //- 通知列表主體 可滾動
    .list-main
      .list-scroll
        //- 通知列表項目
        .list-item.row.margin-none(v-for="(notice, index) in notice_list_data")
          .list-img-box
            img.item-img(
              v-if="!notice.read_stauts",
              src="@/assets/images/notice-on.png"
            )
            img.item-img(v-else, src="@/assets/images/notice-off.png")
          .list-text-box
            .item-text {{ notice.text }}
            .item-time {{ notice.time_text }}
</template>
<script>
export default {
  name: 'noticeArea',
  components: {

  },
  data () {
    return {
      //- list開關
      list_show: false,
      //- 未讀數
      not_read_num: 0,
      //- 訊息data
      notice_list_data: []
    };
  },
  methods: {
    clickNoticeBtn () {
      if (this.not_read_num <= 0) {
        return
      }

      this.list_show = !this.list_show
    }
  }
}
</script>
