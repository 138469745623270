<template lang="pug">
//- 學生登入
.student-login-index.page-index
  Header
  .login-main
    PageTop.bg-gray(page="private-apply")
    .login-form-view
      .form
        .form-title 申請表單

        .form-group
          .form-group-title 請填寫下方表單進行開通
        //- 團體名稱
        .form-group
          .form-group-title 團體名稱
          input.form-group-input(
            type="text",
            placeholder="請輸入團體名稱",
            v-model="group_name"
          )
        //- 聯繫人姓名
        .form-group
          .form-group-title 聯繫人姓名
          input.form-group-input(
            type="text",
            placeholder="請輸入聯繫人姓名",
            v-model="contact_name"
          )

        //- 表格語言
        .form-group
          .form-group-title 語言
          .form-group-radio
            a.radio-option-3(
              @click="changeShowLan('en')",
              v-bind:class="course_lang === 'en' ? 'color-active' : 'color-noactive'"
            ) 美語
              span (English)
            a.radio-option-3(
              @click="changeShowLan('jp')",
              v-bind:class="course_lang === 'jp' ? 'color-active' : 'color-noactive'"
            ) 日語
              span (Japanese)
            a.radio-option-3(
              @click="changeShowLan('ko')",
              v-bind:class="course_lang === 'ko' ? 'color-active' : 'color-noactive'"
            ) 韓語
              span (Korean)

        //- 師資
        .form-group
          .form-group-title 師資
          .form-group-radio
            a.radio-option-3(
              @click="changeShowTea(1)",
              v-bind:class="teacher_lang === 1 ? 'color-active' : 'color-noactive'"
            ) 中文師資
              //- span (Chinese)
            a.radio-option-3(
              @click="changeShowTea(2)",
              v-bind:class="teacher_lang === 2 ? 'color-active' : 'color-noactive'"
            ) 母語師資
              //- span (Japanese)
            a.radio-option-3(
              @click="changeShowTea(3)",
              v-bind:class="teacher_lang === 3 ? 'color-active' : 'color-noactive'"
            ) 皆可
              //- span (Korean)

        .form-group
          .form-group-title 手機號碼

          //- 大視窗
          .d-lg-block.d-md-block.d-sm-block.d-none
            .phonecheck-input-lg
              select.phoneselect.padding-none.float-left(
                v-model="$root.country_code"
              )
                option(
                  v-for="(code, index) in $root.country_codes",
                  :value="code"
                ) {{ code }}

              input.phoneinput.float-left(
                type="tel",
                placeholder="輸入您的手機號碼",
                v-model="cell_phone",
                spellcheck="false"
              )
              .clearfix

            .second-btn-lg(v-if="last_time <= 90 && last_time >= 1") {{ last_time }}
            .second-btn-lg(
              v-else-if="last_time < 1",
              @click="reciprocalTime()"
            ) 重新發送
            .send-btn-lg(v-else, @click="reciprocalTime()") 發送驗證碼
          .clearfix

          //- 小視窗
          .d-lg-none.d-md-none.d-sm-none.d-block
            .phonecheck-input-sm
              select.phoneselect.padding-none.float-left(
                v-model="$root.country_code"
              )
                option(
                  v-for="(code, index) in $root.country_codes",
                  :value="code"
                ) {{ code }}

              input.phoneinput.float-left(
                type="tel",
                placeholder="輸入您的手機號碼",
                v-model="cell_phone",
                spellcheck="false"
              )
              .clearfix
            .second-btn-sm(v-if="last_time <= 90 && last_time >= 1") {{ last_time }}
            .second-btn-sm(
              v-else-if="last_time < 1",
              @click="reciprocalTime()"
            ) 重新發送
            .send-btn-sm(v-else, @click="reciprocalTime()") 發送驗證碼
          .clearfix

        //- 驗證碼
        .form-group
          .form-group-title 驗證碼
          input.form-group-input(
            type="number",
            placeholder="請輸入6位驗證碼",
            v-model="cell_otp"
          )

        //- 按鈕
        .form-group
          .form-group-button.button(@click="confirmPhoneCode")
            span 驗證

        #send-messenger-button
  Footer
</template>
<script>
import Header from '@/components/header_v2'
import Footer from '@/components/footer_v2'
import PageTop from '@/common/page-top'
import firebase from 'firebase'

export default {
  name: 'privateApplyIndex',
  components: {
    Header,
    Footer,
    PageTop
  },
  data () {
    return {
      group_name: '',
      contact_name: '',
      cell_phone: '',
      cell_otp: '',
      last_time: 91,
      course_lang: 'en',
      teacher_lang: 1,
    };
  },
  async created () {
    this.$root.closeLoading()
    setTimeout(() => { this.loadFireBase() }, 250)
    // 指定相關參數
    if (this.$route.params.name) {
      this.contact_name = this.$route.params.name
    }
    if (this.$route.params.phone) {
      this.cell_phone = this.$route.params.phone
    }
  },
  destroyed () {
    this.$root.nav_meta = ''
  },
  mounted () {
    this.$root.nav_meta = 'private-apply'
  },
  methods: {
    reciprocalTime () {
      // 處理倒數計時相關功能
      this.last_time = 90
      this.$options.interval = setInterval(this.runbyeverytime, 1000)

      // 開始請求發送驗證碼
      this.sendPhoneMessage()
    },
    runbyeverytime () {
      // 處理倒數計時秒數 90
      if (this.$data.last_time >= 1) {
        // console.log('--- runbyeverytime ---')
        this.$data.last_time = this.$data.last_time - 1
        // console.log('--> time: ' + this.$data.last_time)
      }
    },
    changeWay (_way) {
      this.$emit('changeWay', _way);
    },
    // Load FireBase
    loadFireBase () {
      // 使用 FireBase Phone Auth
      console.log('--> 載入 FireBase Phone Auth...')
      firebase.auth().languageCode = 'zhTw'
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('send-messenger-button', {
        'size': 'invisible',
        'callback': function () { }
      })
    },
    // 請求發送簡訊
    sendPhoneMessage () {
      // 清空驗證碼欄位
      this.phoneCode = ''
      // 組合電話號碼
      let phoneNumber = this.$root.common.getPhoneCode(this.$root.country_code, this.cell_phone)
      this.$root.cellphoneStep = phoneNumber
      console.log('等待驗證的電話號碼: ' + phoneNumber)
      // 請求發送驗證簡訊
      this.$root.sendPhoneNumberCode(phoneNumber, this.openConfirmCodeInput)
    },
    // 請求驗證簡訊編號
    confirmPhoneCode () {
      // 先驗證表單是否都有填寫
      if (!this.group_name) {
        this.$root.openOptionView({ title: "有需要您注意的事項", text_1: '團體名稱欄位需要填寫哦！', option_1: "瞭解" })
        return
      }
      // 先驗證表單是否都有填寫
      if (!this.contact_name) {
        this.$root.openOptionView({ title: "有需要您注意的事項", text_1: '聯絡人姓名欄位需要填寫哦！', option_1: "瞭解" })
        return
      }

      this.$root.confirmPhoneNumberCode(this.$root.cellphoneStep, this.cell_otp, this.phoneNumberConfirm, false)
    },
    // 當電話號碼被驗證成功
    async phoneNumberConfirm (_phoneNumber) {
      // 請求進行登入操作
      await this.applyAccount()
      await this.$root.apis.usePlatformByPhoneNumber(_phoneNumber, function () { }, function () { })
    },
    //- 顯示語言切換
    changeShowLan (_option) {
      this.course_lang = _option
    },
    //- 顯示師資切換
    changeShowTea (_option) {
      this.teacher_lang = _option
    },
    //- 請求登記帳號申請
    applyAccount () {
      let vm = this
      this.$root.apis.setApplyAccount({
        group_name: this.group_name,
        contact_name: this.contact_name,
        course_lang: this.course_lang,
        teacher_lang: this.teacher_lang,
        cell_phone: this.$root.cellphoneStep,
      }, function () {
        vm.$root.openOptionView({
          title: "企業帳號申請", text_1: "申請已經送出，敬請稍待人員與您聯繫", option_1: "好", option_1_fun: function () {
            location.reload()
          }
        })
      }, function (_response) {
        vm.$root.openOptionView({ title: "有需要您注意的事項", text_1: _response.body.message, option_1: "瞭解", })
      })
    }
  },

  computed: {
  }
}
</script>
<style lang="sass">
</style>
  