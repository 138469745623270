import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/pages/main'


import Home from '@/pages/meta/home'
import Scheduled from '@/pages/meta/scheduled'
import HistoryC from '@/pages/meta/history'
import Setting from '@/pages/meta/setting'
import Bills from '@/pages/meta/bills'
import Buy from '@/pages/meta/buy'
import Profile from '@/pages/meta/profile'
import Live from '@/pages/other/live'


import FrontHome from '@/pages/other/home_v2'
import NewScheduled from '@/pages/other/new-scheduled'
import ScheduledDetail from '@/pages/other/scheduled-detail'
import privateApply from '@/pages/other/private-apply'
import Login from '@/pages/other/student-login'



// import notfound from '@/pages/404'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    //- 主頁
    {
      path: '/',
      name: 'front-home',
      component: FrontHome,
    },
    //- 登入
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    //- 申請入口
    {
      path: '/private-apply',
      name: 'private-apply',
      component: privateApply,
    },
    //- 申請入口 - 待資料
    {
      path: '/private-apply/:name/:phone',
      name: 'private-apply-params',
      component: privateApply,
    },
    //- 主畫面
    {
      path: '/student/main',
      name: 'main',
      component: Main,
    },
    //- 控制台畫面
    {
      path: '/student',
      components: {
        default: Main,
        view: Home,
      },
      children: [
        // home
        {
          path: 'home',
          name: 'home',
          component: Home,
        },
        // scheduled
        {
          path: 'scheduled',
          name: 'scheduled',
          component: Scheduled,
        },
        // history
        {
          path: 'history',
          name: 'history',
          component: HistoryC,
        },
        // setting
        {
          path: 'setting',
          name: 'setting',
          component: Setting,
        },
        // bills
        {
          path: 'bills',
          name: 'bills',
          component: Bills,
        },
        // buy
        {
          path: 'buy',
          name: 'buy',
          component: Buy,
        },
        // profile
        {
          path: 'profile',
          name: 'profile',
          component: Profile,
        },
        // new_scheduled
        {
          path: 'new_scheduled',
          name: 'new_scheduled',
          component: NewScheduled,
        },
        // scheduled_detail
        {
          path: 'scheduled_detail/:id',
          name: 'scheduled_detail',
          component: ScheduledDetail,
        },
        {
          path: 'live/:course_id',
          name: 'live',
          component: Live,
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    },
  ]
})
