<template lang="pug">
//- 精緻團班
.live-in-index.page-index
  //- 內容 TOP (內有 搜尋bar 及 通知區塊 )
  ContentTop
  .row.course-in-content(v-if="show_page")
    .course-in-content-left.padding-right-none
      .course-in-main
        .course-in-title
          .title {{ course.course_name }}
          .text(v-if="course.course_regtime") 上課時間: {{ course.course_regtime }}
        .live-screen
          VideoPlayer.bigimg(
            ref="readMedia",
            :autoplay="true",
            :source="course.course_live_uri",
            :course_id="course.course_id",
            :start_time="play_start",
            :course_type="course_type",
            @playStatus="setPlayStatus"
          )
    .course-in-content-right.padding-none(v-if="course_type === 'course'")
      //- 課程資訊
      .course-info-box
        //- 課程title
        .course-title {{ course.course_name }}

        //- 課程類別
        .course-group-50
          .course-sm-title 類別
          .course-sm-content 專屬課程

        //- 課程點數
        .course-group-50
          .course-sm-title 點數
          .course-sm-content {{ course.course_live_point }}點
        .clearfix

        //- 課程語言
        .course-group-50
          .course-sm-title 語言
          .course-sm-content(v-if="course.course_lang === 'en'") 美語
          .course-sm-content(v-if="course.course_lang === 'ja'") 日語
          .course-sm-content(v-if="course.course_lang === 'ko'") 韓語

        //- 課程總長
        .course-group-50
          .course-sm-title 課程總長
          .course-sm-content {{ course.course_minutes }}min
        .clearfix

        //- 課程描述
        .course-group-100
          .course-sm-title 課程描述
          .course-sm-content.limit-text {{ course.course_directions }}

        //- 授課教師
        .course-group-100
          .course-sm-title 授課教師
          .teacher-btn.btn.bg-blue-1 {{ course.teacher_info.name }}
          //- 禮物按鈕
          //- .gift-imgbox(@click="$root.openGift()")
            img.gift-img(src="@/assets/images/gift.png")
          //- 讚 按鈕
          //- .good-imgbox(@click="clickGoodBtn()")
            img.good-img(
              v-if="!course.good_status",
              src="@/assets/images/good-gray.png"
            )
            img.good-img.animated-3.zoomIn(
              v-else,
              src="@/assets/images/good-blue.png"
            )
          .clearfix

        //- 教材預覽
        //- .textbook-box
          img.text-book-img(src="@/assets/images/textbook.png")
          .textbook-content
            .textbook-title 教材預覽
            //- .textbook-textbox
              .textbook-text(v-if="!course.textbook_status") 教材尚未開放
              .textbook-text(v-else) 教材已開放預覽
            //- .textbook-btnbox
              .textbook-btn.btn.bg-gray-2(v-if="!course.textbook_status") 尚未開放
              .textbook-btn.btn.bg-orange(v-else) 前往預覽
            .textbook-textbox
              .textbook-text 教材尚未開放
      //- 留言區塊
      //- .course-comment-box
      //- 留言title
      //- .comment-title 訊息
      //- .comment-over
        .comment-all
          .comment-item(v-for="(comment, index) in comment_data")
            .text-type(v-if="comment.type === 'text'")
              .name {{ comment.name }}:
              .text {{ comment.text }}
              .clearfix

            .gift-type(v-if="comment.type === 'gift'")
              .gift-text {{ comment.name }} 送 {{ course.teacher_info.name }} {{ comment.text }}！
          .clearfix
      //- input.comment-input(type="text", placeholder="輸入您的想對老師說的 ...")
</template>
<script>
import $ from 'jquery'
import ContentTop from '@/components/content-top/content-top'
import VideoPlayer from '@/components/video.vue'
export default {
  coures_teacher: {
    name: 'CourseIn',
  },
  components: {
    ContentTop,
    VideoPlayer
  },
  data () {
    return {
      play_start: 0,
      course_id: false,
      course_type: false,
      show_page: false,
      live_status: false,
      course: {},
      comment_data: []
    };
  },
  async created () {
    // 獲取需要處理的課程ID
    this.course_id = this.$route.params.course_id
    this.course_type = this.$route.params.course_type
    // 獲取課程細節資料
    if (this.course_type === 'course') {
      await this.getCourseDetail('course')
    }
    // 處理直播再現資料
    if (this.course_type === 'reappear') {
      await this.getCourseDetail('reappear')
    }
    if (this.course_type === 'region') {
      this.course = this.$root.live_view_course
      // 開啟課程內頁
      this.show_page = true
    }
    // 處理新生學園資料
    else if (this.course_type === 'park') {
      // 獲得課程相關資訊
      this.course = await this.$root.new_park_view_course
      // 開啟課程內頁
      this.show_page = true
    }
    // 關閉載入中
    await this.$root.closeLoading()
    // 處理畫面程序
    await this.loadView()
  },
  mounted () {
  },
  watch: {
    'course.order_success' (_status) {
      if (_status) {
        $('.course-in-main').css({ 'padding-bottom': '30vmin' })
      }
      if (!_status) {
        $('.course-in-main').css({ 'padding-bottom': '5vmin' })
      }
    }
  },
  computed: {
  },
  methods: {
    loadView () {
      var vm = this
      $(document).ready(function () {
        if (vm.course.order_success) {
          $('.course-in-main').css({ 'padding-bottom': '30vmin' })
        }
        if (!vm.course.order_success) {
          $('.course-in-main').css({ 'padding-bottom': '5vmin' })
        }
        // 前往最底部
        vm.scrollBottom()
      })
    },
    // 取消預約此課程
    cancelOrder () {
      this.$root.openOptionView({
        title: "取消預約課堂",
        text_1: "您確定要取消 課堂",
        text_2: "開課前8小時以前取消，將退一半點數",
        course_text: '',
        option_1: "確認",
        option_2: "我再考慮",
        option_1_fun: false,
        option_2_fun: false
      })
    },
    // 對課程教師按讚
    clickGoodBtn () {
      this.course.good_status = !this.course.good_status
    },
    // 請求獲取課程資料
    async getCourseDetail (_type = 'course') {
      // 開啟載入中
      this.$root.useLoading()
      // 獲得課程清單
      await this.$root.apis.getCourseDetail(this.course_id, _type, this.processCourseDetailSuccess, this.processCourseDetailError)
      // 開啟課程內頁
      this.show_page = true
      // 關閉載入中
      this.$root.closeLoading()
    },
    // 處理回傳成功的課程資料
    processCourseDetailSuccess (_response) {
      if (_response.body.error_code !== 0) {
        return this.processCourseDetailError(_response)
      }

      // 處理獲得的課程資料
      let course = _response.body.data

      // if (course.own_course.length > 0) {
      //   course.order_success = true
      // }

      course.can_enter_status = false

      // 傳遞課程資料
      this.course = course

      // 處理錄播程序
      if (course.course_record) {
        // 獲得課程開始的時間
        let startTime = Math.floor(new Date(course.course_regdate + ' ' + course.course_regtime).getTime() / 1000)
        console.log('--> 課程開始的時間: ', startTime)
        // 獲取目前時間
        let nowTime = this.$root.common.getNowTime('timestamp')
        console.log('--> 獲得目前的時間: ', nowTime)
        // 獲得相差的時間秒數
        this.play_start = nowTime - startTime
        console.log('--> 距離開始的秒數: ', this.play_start)
      }
    },
    // 處理回傳失敗的課程資料
    processCourseDetailError () {

    },
    // 請求開啟課程
    openCourse () {
      // 會議室與直播都有預約
      if (this.course.order_meeting && this.course.order_live) {
        this.$root.openOptionView({
          title: "開始上課",
          text_1: "請選擇您要上課的方式",
          option_1: "進入互動課程",
          option_2: "進入直播間",
          option_1_fun: this.startOpenMeeting,
          option_2_fun: this.startOpenLiveView,
        })
      }

      // 只有預約會議室
      else if (this.course.order_meeting) {
        this.startOpenMeeting()
      }

      // 只有預約直播
      else if (this.course.order_live) {
        this.startOpenLiveView()
      }
    },
    // 前往最底部
    scrollBottom () {
      var h = $('.course-comment-box .comment-all').height();
      $('.comment-over').animate({
        scrollTop: h
      }, 300);
    },
    // 設定播放狀態
    setPlayStatus (_status) {
      this.live_status = _status
    }
  }
}
</script>
<style lang="sass">
</style>
