<template lang="pug">
.home-banner
  .banner-100
    img.banner.d-lg-block.d-md-block.d-sm-none.d-xs-none.d-none(
      src="@/assets/image/big-banner-v5.jpg"
    )
    img.banner.d-lg-none.d-md-none.d-sm-block.d-xs-block.d-block(
      src="@/assets/image/big-banner-v5.jpg"
    )

  //- 在banner下面的
  .d-lg-none.d-md-none.d-sm-block.d-xs-block
    .banner-text
      .title-text
        .title-text.font-weight-bold.font-size-lg 全球華人最大美日韓語
        .title-text.font-weight-bold.font-size-lg 真人直播互動平台
      .group-ps
        .ps-text 100%政府合格中外籍教師教學
</template>
<script>
// import $ from 'jquery'
export default {
  name: 'homeBanner',
  components: {
  },
  props: {},
  data () {
    return {

    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
