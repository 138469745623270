<template lang="pug">
//- 首頁
.home-index-v2.page-index
  homeHeader
  .home_banner_box
    .home_banner(:style="'background-image: url(' + bannerBg + ');'")
    a(href="/private-apply", target="_blank") 
      img.home_banner_img(src="@/assets/v2_image/Banner.svg")

  .home_content.container-fluid.v2
    .home_content_top
      .home_content_title
        .title_text 地球村是
        .title_text 專屬客製課程學習平台
      .home_content_text 
        .text_text
          .text-left 個性化學習: 
          .text-left 依照您的學習進度進行課程規劃
        .text_text
          .text-left 彈性上課時間: 
          .text-left 不再需要因為課程時間而煩惱         
    .home_content_list 
      .home_content_item
        img(src="@/assets/v2_image/item1.svg")
        .item_title 量身打造專屬課程
        .item_text 根據你的需求，打造專靥於個人/公司/團體的课程
      .home_content_item
        img(src="@/assets/v2_image/item2.svg")
        .item_title 中外籍教師
        .item_text 教學經驗豊富的專業教師，提供你最好的課程體驗
      .home_content_item
        img(src="@/assets/v2_image/item3.svg")
        .item_title 快速學習成長
        .item_text 適合自己的更能創造更好的學習成果
      .home_content_item
        img(src="@/assets/v2_image/item4.svg")
        .item_title 線上超優學習
        .item_text 依據你的時不限於地點的進行學習
      .home_content_item
        img(src="@/assets/v2_image/item5.svg")
        .item_title 互動學習
        .item_text 地球村可提供優良實體互動共學的學習場所
      .home_content_item
        img(src="@/assets/v2_image/item6.svg")
        .item_title 教學資源豐富
        .item_text 地球村40年累積有5萬多篇豊富的教學資源

  .home_apply
    .apply_sm_text 開始投資自己的未來
    .apply_title 讓我們開始教育吧！
    .apply_text 我們的教學團隊和課程被公認為最高標準。 我們在提供高質量語言課程方面享有良好的國際聲譽。
    //- .input_group
      input.apply_input(type='text' placeholder="聯絡姓名",v-model="userName")
      input.apply_input(type='text' placeholder="聯絡電話",v-model="userPhoneNumber")
      .apply_btn(@click="goFormPage") 立刻諮詢






  //- homeBanner
  //- homeEdu
  //- homeVideo
  homeFooter
</template>

<script>
// import $ from 'jquery'
import homeHeader from '@/components/header_v2'
import homeBanner from '@/components/front-home/home-banner'
import homeEdu from '@/components/front-home/home-edu'
import homeVideo from '@/components/front-home/home-video'
import homeFooter from '@/components/footer_v2'

import bannerBg from '@/assets/v2_image/Background.svg'

export default {
  name: 'Index',
  components: {
    homeHeader,
    homeBanner,
    homeEdu,
    homeVideo,
    homeFooter,
  },
  data () {
    return {
      userName: '',
      userPhoneNumber: '',
      bannerBg: bannerBg
    };
  },
  created () {
    this.$root.closeLoading()
    // this.$router.push({ name: 'login' })
  },
  destroyed () {
    this.$root.nav_meta = ''
  },
  mounted () {
    this.$root.nav_meta = 'front-home'
  },
  computed: {
  },
  methods: {
    goFormPage () {
      if (!this.userName) {
        return this.$root.openOptionView({ title: "資料未填寫", text_1: "您需要填寫姓名哦", option_1: "瞭解", })
      }
      if (!this.userPhoneNumber) {
        return this.$root.openOptionView({ title: "資料未填寫", text_1: "您需要填寫電話哦", option_1: "瞭解", })
      }

      this.$root.goPage('private-apply', 1, {
        name: this.userName,
        phone: this.userPhoneNumber
      })
    }
  }
}
</script>