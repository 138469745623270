<template lang="pug">
//- 今日排課
.today-recommend-view
  .recommend-top
    .recommend-title.cant-select 今日排課
    .clearfix
  .recommend-list
    ScheduledItem.recommend-list-item(
      v-for="course in courseData"
      :course="course"
      @reLoadCourseList="getStudentRecommendCourse"
    )
    .clearfix
</template>
<script>
import ScheduledItem from '@/common/scheduled-item'
export default {
  name: 'todayRecommend',
  components: {
    ScheduledItem
  },
  props: ['courseData'],
  data () {
    return {
      courses: [],
    };
  },
  async created () {
    // await this.getStudentRecommendCourse()
  },
  methods: {
    // 請求獲取今日推薦課程清單
    async getStudentRecommendCourse () {
      let nowDate = this.$root.common.getNowTime('date')
      await this.$root.apis.getStudentRecommendCourse('MP', 'all', nowDate, this.processStudentRecommendCourseSuccess, this.processStudentRecommendCourseError)
    },
    // 當獲取課表清單成功時
    async processStudentRecommendCourseSuccess (_response) {
      if (_response.body.error_code !== 0) {
        return this.processStudentRecommendCourseError(_response)
      }
      // 處理課程資料
      let courses = _response.body.data
      // 灌回課程列表
      this.courses = courses
    },
    // 當獲取課表清單失敗時
    async processStudentRecommendCourseError () {

    }
  }
}
</script>
