<template lang="pug">
#app
  router-view
  //- loading畫面
  loadingView(:isVisible="$root.isAllLoading")

  GoTopButton
  //- alert
  AlertView(:isVisible="$root.isAlert")

  //- 畫面縮小後partbar
  GuideView(v-if="$root.isGuide && $root.deviceType !== 'web'")
</template>

<script>
import loadingView from '@/components/loading'
import GoTopButton from '@/common/go-top-button'
import AlertView from '@/components/alert'
import GuideView from '@/components/guide'
export default {
  name: 'App',
  components: {
    loadingView,
    GoTopButton,
    AlertView,
    GuideView
  }
}
</script>
<style lang='sass'>
#app
  background: #fff
  width: 100%
  height: 100%
  overflow-y: auto
  overflow-x: auto
</style>
