<template lang="pug">
//- B端 FOOTER
footer.home-footer.v2
  //- .foot-top-hr
  //- 主要內容，分左右兩塊
  .container-fluid.v2
    .footer_row
      //- 左邊，LOGO 和社群
      .footer_content
        a.footer-logo
          img(src="@/assets/image/logo.png")
          //- span 地球村專屬課

        .footer-text 我們正在為我們的學生提供最好和最合適的服務，以建設他們美好的未來。
        .footer-text 

          span 周一至周五 09:00~22:00
          span 週六、週日 09:00~17:00
          span 客服專線 02-55561111
        //- .footer-system-list
          img(src="@/assets/image/appstore.png")
          img(src="@/assets/image/googleplay.png")

      //- 右邊，一堆選單
      .footer_list_box
        .footer_list
          .footer-title 地球村資源
          .footer-list-content.align-items-center.flex-nowrap(
            v-for="item in arrList",
            @click="$root.goPage(item.page, item.type)"
          )
            //- img.foot-list-img(src="@/assets/image/list-img.png")
            a.footer-list-item {{ item.name }}

        //- .footer_list
          .footer-title 關於我們
          .footer-list-content.align-items-center.flex-nowrap(
            v-for="lan in searchList",
            @click="$root.goPage(lan.page, lan.type)"
          )
            //- img.foot-list-img(src="@/assets/image/list-img.png")
            a.footer-list-item {{ lan.name }}

        .footer_list
          .footer-title 學員
          .footer-list-content(v-for="sup in supList")
            a.footer-list-item {{ sup.name }}

  //- copy right
  .container-fluid.text-align-center.v2
    .address
      span Copyright © 2021 地球村文教機構 版權所有．轉載必究
</template>
<script>
export default {
  name: 'homeFooter',
  components: {
  },
  data () {
    return {
      arrList: [

        {
          name: '地球村愛學習',
          page: 'https://gvoedu.com/web',
          type: 1
        },
        {
          name: '地球村線上學',
          page: 'https://www.edu1n1.com/',
          type: 1
        },
        // {
        //   name: '學員後台',
        //   page: '',
        //   type: 1
        // },
        // {
        //   name: '教師後台',
        //   page: '',
        //   type: 1
        // }
      ],
      searchList: [
        {
          name: '關於我們',
          page: '',
          type: 1
        },
        {
          name: '我們正在招聘',
          page: '',
          type: 1
        },
        {
          name: '我們的團隊',
          page: '',
          type: 1
        },
        {
          name: '公司信息',
          page: '',
          type: 1
        }
      ],
      supList: [
        // {
        //   name: '團班課表',
        //   page: 'timetable',
        //   type: 1
        // },
        {
          name: '隱私權條款',
          page: 'privacy',
          type: 1
        },
        {
          name: '條款及細則',
          page: 'rule',
          type: 1
        },
      ],
    };
  },
  methods: {

  }
}
</script>
