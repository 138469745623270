<template lang="pug">
//- 內容 top
.content-top.row
  .col-4
    //- 搜尋bar 
    //- SearchBar
  .col-7
  .col-1.padding-none
    //- 通知
    NoticeArea
</template>
<script>
import SearchBar from '@/components/content-top/search-bar'
import NoticeArea from '@/components/content-top/notice-area'
export default {
  name: 'contentTop',
  components: {
    SearchBar,
    NoticeArea
  },
  data () {
    return {

    };
  },
  methods: {

  }
}
</script>
