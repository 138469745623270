<template lang="pug">
//- Alert PAGE
.alert-wrapper(v-if="isVisible")
  .alert-box
    //- 一般Alert
    NormalAlert
</template>
<script>

import NormalAlert from '@/common/alert-normal'
export default {
  name: 'Alert',
  props: {
    isVisible: {
      type: Boolean,
      default: true
    }
  },
  components: {
    NormalAlert
  },
  computed: {

  },
  created () {
  },
  data () {
    return {

    };
  }
}
</script>
