<template lang="pug">
//- 搜尋bar
.seacher-bar
  input.search-input(type="text", placeholder="您想找哪位教師？")
  .search-img 
    img(src="@/assets/images/ic-search@3x.png")
</template>
<script>
export default {
  name: 'searchBar',
  components: {

  },
  data () {
    return {

    };
  },
  methods: {

  }
}
</script>
