<template lang="pug">
//- 主框架畫面
.main-Index.page-index
  .row.main-row
    //- 側邊選單
    .side-frame.padding-none
      SideMenuBar
    //- 主內容
    .content-frame.padding-none.bg-color
      router-view
      //- loading畫面
      loadingView(:isVisible="$root.isSmLoading")
</template>
<script>
// import $ from 'jquery'
import SideMenuBar from '@/components/side-menubar'
import loadingView from '@/components/loading'
export default {
  name: 'Index',
  components: {
    SideMenuBar,
    loadingView
  },
  data () {
    return {
    };
  },
  async created () {
    // 只在 Main 強制到首頁
    if (this.$route.name === 'main') {
      this.$root.goPage("home")
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="sass">
</style>
