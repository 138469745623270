
<template lang="pug">
//- 點數購買
.buypoint-tab-two-view
  .row.home-content
    .content-left-1.padding-right-none.buy-left
      .buypoint-titile 點數方案
      .buypoint-text 選擇你想購買的方案
      .buy-item-group
        //- .item-bg-rbga
        .buy-item.click-cursor(
          v-for="(pro, index) in programs",
          v-bind:class="pro.click_status ? 'item-bg-purple' : 'item-bg-gray'",
          @click="clickItem(index)"
        )
          //- 點數
          .item-point(
            v-bind:class="pro.click_status ? 'item-white' : 'item-black'"
          ) 課程 {{ pro.point }} 點

          //- 期限
          .item-month(
            v-bind:class="pro.click_status ? 'item-black' : 'item-orange'"
          ) 
            span 期限 {{ pro.limit }}天

          //- 金額
          .item-money(
            v-bind:class="pro.click_status ? 'item-money-blue' : 'item-money-gray'"
          ) NT$ {{ pro.nt }}

          //- 優惠
          .item-discount(v-if="pro.status === 'discount'") 最優惠

    .content-right-1
      .buypoint-titile 您的訂單資訊
      .order-info(
        v-for="(pro, index) in programs",
        v-if="pro.click_status && click_status"
      )
        .order-info-point {{ pro.point }}點
        .order-info-nt NT$ {{ pro.nt }}
        .clearfix

      .order-info(v-if="!click_status")
        .order-info-point －－－－
        .clearfix

      .order-payway
        .buypoint-titile 付款方式
        .form
          .form-group
            .payway-select-box
              select.payway-box(
                v-model="pay_status",
                v-bind:class="pay_status !== 0 && pay_status !== '0' ? 'color-blue' : 'color-gray'"
              )
                option(v-for="(pay, index) in pay_data", :value="pay.id") {{ pay.pay_way }}
              img.downarrow-img(src="@/assets/images/triangle@3x.png")

      .order-gopay.click-cursor(
        v-if="pay_status && click_status",
        @click="createOrder()"
      ) 前往付款
</template>

<script>
export default {

  data () {
    return {
      click_status: false,
      pay_status: 0,
      pay_data: [
        {
          id: '0',
          platform: '',
          pay_way: '請選擇支付方式'
        },
        {
          id: '1',
          platform: 'Android',
          pay_way: '綠界信用卡'
        },
        {
          id: '2',
          platform: 'iOS',
          pay_way: 'In-App Purchase'
        }
      ],

      programs: [
        {
          point: '180',
          click_status: false,
          limit: '10',
          nt: '32,900',
          status: '',
        },
        {
          point: '123',
          click_status: false,
          limit: '123',
          nt: '123',
          status: '',
        },
        {
          point: '123',
          click_status: false,
          limit: '123',
          nt: '123',
          status: '',
        }
      ],
    }
  },
  watch: {

  },
  created () {

  },
  methods: {
    clickItem (_index) {
      var vm = this
      this.programs.forEach(function (_item, _itemIndex) {
        vm.programs[_itemIndex].click_status = false
      })
      this.programs[_index].click_status = true
      this.click_status = true
    },
    // 請求建立訂單
    // async createOrder () {
    //   var vm = this
    //   let payment = this.pay_status
    //   let program = {}

    //   this.programs.forEach(function (_row) {
    //     if (_row.click_status) {
    //       program = _row.code
    //       vm.program_code = _row.code
    //     }
    //   })


    //   // 針對 iOS 平台預先處理方案
    //   if (this.$root.platform === 'iOS' && payment === '2') {
    //     let canBuy = await this.$root.core.checkCanPurchase(program)
    //     if (!canBuy) {
    //       this.$root.openOptionView({
    //         title: "方案選購發生問題",
    //         text_1: "您似乎目前無法購買這個方案",
    //         option_1: "瞭解",
    //       })
    //       return
    //     }
    //   }

    //   await this.$root.useLoading("正在建立訂單")
    //   await this.$root.apis.createStudentPlanOrder({
    //     payment: payment,
    //     program: program
    //   }, this.processOrderBuildSuccess, this.processOrderBuildError)
    // },
    // 處理訂單建立成功
    // async processOrderBuildSuccess (_response) {
    //   if (_response.body.error_code !== 0) {
    //     return this.processOrderBuildError(_response)
    //   }

    //   // 處理訂單完成相關操作
    //   this.order = _response.body.data
    //   // 針對綠界訂單進行處理
    //   if (this.order.payment === '1') {
    //     // 開啟準備結帳流程
    //     this.$root.useLoading("準備使用綠界付款")
    //     // 開啟 WebView 前往付款
    //     this.$root.core.openFullView(this.$root.site_path + '/api/payment/goEcpayPayment/' + this.order.number)
    //     setTimeout(() => {
    //       this.$root.closeLoading()
    //     }, 5000)
    //   }

    //   // 針對 Apple In-App Purchase 處理付款
    //   if (this.order.payment === '2') {
    //     // 開啟準備結帳流程
    //     this.$root.useLoading("準備使用 Apple In-App Purchase 付款")
    //     this.$root.core.AskUseApplePay(this.program_code, this.order.number)
    //   }
    // },
    // processOrderBuildError () {
    //   this.$root.closeLoading()
    //   this.$root.openOptionView({
    //     title: "目前發生問題",
    //     text_1: "您的訂單似乎遭遇了問題, 請稍後再試",
    //     option_1: "瞭解",
    //   })
    // },
    // 付款完成的操作
    // async payDone (_data) {
    //   // 通報訂單完成
    //   console.log('--> 通報訂單完成: ', this.order.number)
    //   await this.$root.core.putLog('[APP] *內購通報* 訂單[' + this.order.number + ']交易完成的資料: ' + JSON.stringify(_data.id))
    //   // 請求進行通報
    //   await this.$root.apis.appleInPurchaseSuccess(this.order.number, _data.transaction, this.processAppleInPurchaseSuccess, this.processAppleInPurchaseError)
    // },
    // 訂單通報完成
    // processAppleInPurchaseSuccess (_response) {
    //   if (_response.body.error_code !== 0) {
    //     return this.processOrderBuildError(_response)
    //   }
    //   // 設置訂單完成狀態
    //   this.$root.paymentSuccess = true
    //   // 處理 UI 流程
    //   this.$root.closeLoading()
    //   // 完成付款操作
    //   // this.buyConfirm()
    // },
    // processAppleInPurchaseError () {
    //   this.$root.closeLoading()
    //   this.$root.openOptionView({
    //     title: "付款發生問題",
    //     text_1: "您的付款似乎遭遇了問題",
    //     option_1: "瞭解",
    //   })
    // },
  }
}
</script>
<style lang="sass" scoped>
</style>
