<template lang="pug">
//- 學員設置
.setting-index.page-Index
  //- 內容 TOP (內有 搜尋bar 及 通知區塊 )
  ContentTop

  .setting-top
    .setting-title 學員設置
  .setting-text 用戶帳號會期：20220116
  .setting-content
    .setting-list-table-scroll
      table.setting-list-table
        thead
          tr
            th No
            th 姓名
            th 手機號碼
            th 

        tbody
          tr(v-if="setting_data.length === 0")
            td.text-align-center(colspan="5") 目前無資料
          tr(v-else v-for="(sc,index) in setting_data")
            td 0{{index}}
            td {{sc.name}}
            td {{sc.phone}}
            td.setting-table-btns
              .setting-item-btn_1.click-cursor －



    .setting-search_btn.click-cursor 查詢







  .list-bottom(v-if="setting_data.length !== 0")
    nav
      ul.pagination.pagination-rounded
        li.disabled(v-if="page > 1")
          a(@click="jumpList(page, 1)", aria-label="Previous")
            i.fa.fa-chevron-left.first

        li(
          v-for="pre_page in totalPage",
          v-if="pre_page < page + 4 && pre_page > page - 4",
          :class="page == pre_page ? 'active' : ''"
        )
          a(@click="jumpList(pre_page)")
            | {{ pre_page }}

        li(v-if="page < totalPage")
          a(@click="jumpList(page, 2)", aria-label="Next")
            i.fa.fa-chevron-right.last
</template>
<script>
import $ from 'jquery'
import ContentTop from '@/components/content-top/content-top'

export default {
  name: 'Index',
  components: {
    ContentTop
  },
  data () {
    return {
      setting_data: [
        {
          name: 'vic',
          phone: '0987654321'
        },
        {
          name: 'vic',
          phone: '0987654321'
        },
        {
          name: 'vic',
          phone: '0987654321'
        },
        {
          name: 'vic',
          phone: '0987654321'
        },
        {
          name: 'vic',
          phone: '0987654321'
        }
      ],
      page: 1,
      totalPage: 0,
    };
  },
  async created () {
    // 請求進行更新學員資料
    // await this.$root.apis.getSystemInfo()
    // 關閉載入中畫面
    await this.$root.closeLoading()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    //- 回至頂部
    goTop () {
      $('.profile-index').animate({
        scrollTop: 0
      }, 200)
    },
    //- 跳頁
    jumpList (_page = '', _opt = '') {
      if (_page) {
        this.page = (_page)
      }
      if (_opt === 1) {
        this.page = this.page - 1
      }
      if (_opt === 2) {
        this.page = this.page + 1
      }
      this.goTop()
    },
  }
}
</script>
<style lang="sass">
</style>
