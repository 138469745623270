<template lang="pug">
.home-area.edu-area
  .blue-text.font-weight-bold 投資自己 掌握未來
  //- .area-title
    .title-text.font-weight-bold.font-size-lg 擔得起的在線課程和學習機會
  //- .area-ps
    .ps-text-part-2 與傳統方式相比，找到自己的空間並利用更好的學習選擇可以更快地取得成果。享受電子學習的美麗！
  .row
    .col-2
    .col-8
      .card-group.row
        .col-lg-3.col-md-6.col-sm-6.card-p(v-for="(card, index) in card_data")
          .card-item
            .card-item-img
              img(v-if="index + 1 === 1", src="@/assets/image/item-1-2.png")
              img(v-if="index + 1 === 2", src="@/assets/image/item-2-2.png")
              img(v-if="index + 1 === 3", src="@/assets/image/item-3-2.png")
              img(v-if="index + 1 === 4", src="@/assets/image/item-4-2.png")
            .card-item-title.font-weight-bold {{ card.title }}
              span {{ card.title_span }}
            .card-item-text.font-weight-bold {{ card.text_1 }}
            .card-item-text.font-weight-bold(v-if="card.text_2") {{ card.text_2 }}
    .col-2
</template>
<script>
// import $ from 'jquery'
export default {
  name: 'homeEduArea',
  components: {
  },
  props: {},
  data () {
    return {
      card_data: [
        {
          title: '地球村',
          title_span: '美日韓語',
          text_1: '美語 日語 韓語全系列會話課程',
          text_2: '優質會話'
        }, {
          title: '美爾頓',
          title_span: '證照檢定',
          text_1: '多益 托福 雅思 全民英檢',
          text_2: '日語檢定 韓語檢定'
        },
        {
          title: '美爾頓',
          title_span: '專業課程',
          text_1: '文法專班 字彙專班 商用專班',
          text_2: '觀光專班 寫作專班'
        },
        {
          title: '專屬個人課程',
          title_span: '',
          text_1: '可依個人語言程度、學習計畫及實務需求線上預約，由專業人員規劃最合適的課程',
          text_2: ''
        }
      ]
    };
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
