/* eslint-disable no-redeclare */
import Vue from 'vue'

var project
export default class Common {
    constructor(_vm) {
        project = _vm
    }

    // 產生 UUID
    createUUID () {
        var d = Date.now();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    // 處理電話號碼
    getPhoneCode (_countryCode = '+886', _regPhone = '') {
        if (_regPhone.substr(0, 1) === '+') {
            return _regPhone
        } else {
            if (_countryCode === '+886') {
                if (_regPhone.substr(0, 1) === '0') {
                    return _countryCode + _regPhone.substr(1)
                } else {
                    return _countryCode + _regPhone
                }
            } else {
                return _countryCode + _regPhone
            }
        }
    }

    // 取得 preview URL
    async getMediaPreviewUri (_attachmentID, _onlyUrl = false) {
        if (!_attachmentID) {
            return ''
        }

        let url = project.base_url + 'app/attachment/' + _attachmentID + '/preview?DeviceToken=' + Vue.localStorage.get('api-token')

        if (_onlyUrl) {
            // console.log('---> 返回網址: ' + url)
            return url
        }

        let ext = await project.getAttachmentExt(_attachmentID)
        // console.log('---> 獲取副檔名: ', ext)

        if (!_onlyUrl) {
            // console.log('---> 返回物件: ', { ext: ext, url: url })
            return { ext: ext, url: url }
        }
    }

    // 格式化數字
    formatNumber (num = 0, _format = 2) {
        if (!num) {
            return 0.00
        } else {
            Number.prototype.numberFormat = function (c, d, t) {
                var n = this,
                    c = isNaN(c = Math.abs(c)) ? 2 : c,
                    d = d == undefined ? "." : d,
                    t = t == undefined ? "," : t,
                    s = n < 0 ? "-" : "",
                    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
                    j = (j = i.length) > 3 ? j % 3 : 0;
                return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
            }

            var tempNumber = parseFloat(num)
            var number = tempNumber.numberFormat(_format, '.', ',')
            console.log('---> formatNumber: ' + num + ' to ' + number)
            return number
        }
    }


    // 取得目前時間
    getNowTime (_type = 'datetime', _target = 0) {
        // 取得日期
        var today
        if (_target) {
            if (Number.isInteger(_target)) {
                today = new Date(_target * 1000)
            } else {
                today = new Date(_target);
            }
        } else {
            today = new Date()
        }

        var weeks = ['日', '一', '二', '三', '四', '五', '六']

        var timestamp = Math.floor(today / 1000)
        var nowYear = today.getFullYear()
        var nowMonth = (today.getMonth() + 1)
        var nowDate = today.getDate()
        var nowHour = today.getHours()
        var nowMin = today.getMinutes()
        var nowSec = today.getSeconds()
        var nowWeek = weeks[today.getDay()]

        // 2020-03-03
        if (_type === 'date') {
            return this.paddingLeft(nowYear.toString(), 2) + '-' + this.paddingLeft(nowMonth.toString(), 2) + '-' + this.paddingLeft(nowDate.toString(), 2)
        }

        if (_type === 'date-day') {
            return this.paddingLeft(nowDate.toString(), 2)
        }

        // Week 3
        if (_type === 'week') {
            return today.getDay()
        }

        // 04-03(三)
        if (_type === '2date') {
            return this.paddingLeft(nowMonth.toString(), 2) + '/' + this.paddingLeft(nowDate.toString(), 2) + '(' + nowWeek + ')'
        }

        // 2020
        if (_type === 'year') {
            return nowYear
        }

        // 01
        if (_type === 'month') {
            return this.paddingLeft(nowMonth.toString(), 2)
        }

        // 2020-03-03 15:15:15
        if (_type === 'datetime') {
            return nowYear + '-' +
                this.paddingLeft(nowMonth.toString(), 2) + '-' +
                this.paddingLeft(nowDate.toString(), 2) + ' ' +
                this.paddingLeft(nowHour.toString(), 2) + ':' +
                this.paddingLeft(nowMin.toString(), 2) + ':' +
                this.paddingLeft(nowSec.toString(), 2)
        }

        // 2020-03-03 15:00
        if (_type === 'datetime1') {
            return nowYear + '-' +
                this.paddingLeft(nowMonth.toString(), 2) + '-' +
                this.paddingLeft(nowDate.toString(), 2) + ' ' +
                this.paddingLeft(nowHour.toString(), 2) + ':' +
                this.paddingLeft(nowMin.toString(), 2)
        }
        // 15:15
        if (_type === 'time') {
            return this.paddingLeft(nowHour.toString(), 2) + ':' + this.paddingLeft(nowMin.toString(), 2)
        }

        if (_type === 'timestamp') {
            return timestamp
        }
    }

    // 補0
    paddingLeft (str, lenght) {
        if (str.length >= lenght) { return str } else { return this.paddingLeft('0' + str, lenght) }
    }

    // 開啟直播頁
    goLive (_courseID = false, _type = 'course') {
        project.$router.push({ name: 'live', params: { course_id: _courseID, course_type: _type } })
    }

    // 開啟會議室
    goMeet (_type = 0, _courseID = false) {
        let uri = project.api_path + '/meeting/goCourseRoom/' + _courseID + '/' + _type + '?OAUTH-API-KEY=' + project.auth_token
        // console.log('=> 進入會議室鏈結:', uri)
        window.open(uri, '正式課程環境',
            'height=788, width=1400, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no'
        )
    }

    /**
     * 獲得裝置類型
     * @returns 
     */
    getDeviceType () {
        // var URL = document.location.toString()
        var useragent = navigator.userAgent
        console.log('--> 裝置 User Agent: ', useragent)
        useragent = useragent.toLowerCase()


        // 手機裝置
        if (useragent.indexOf('iphone') != -1) {
            return 'iPone'
        }

        // 平板裝置
        else if (useragent.indexOf('ipad') != -1 || useragent.indexOf('ipod') != -1) {
            return 'iPad'
        }

        // Android 裝置
        else if (useragent.indexOf('android') != -1) {
            if (screen.width >= 1024 && screen.height >= 600) {
                return 'Android Pad'
            } else {
                return 'Android Phone'
            }
        }

        // 純網頁裝置
        else {
            return 'web'
        }
    }

    checkDeviceIsPC () {
        var userAgentInfo = navigator.userAgent.toLowerCase();
        console.log('--> 裝置 User Agent: ', userAgentInfo)
        var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v].toLowerCase()) > 0) {
                flag = false;
                break;
            }
        }
        if (flag) {
            if (typeof navigator.standalone != "undefined") {
                flag = false;
            }
        }

        if (flag) {
            return 'web'
        }

        return 'Other';
    }
}