var project
export default class WebSocketLib {
    constructor(_vm) {
        project = _vm
    }

    // 初始化weosocket
    initWebSocket () {
        // 設置目標
        let wsuri
        if (project.system_version === 'dev') {
            wsuri = 'wss://' + project.api_socket + ':12019'
        } else if (project.system_version === 'local') {
            wsuri = 'ws://' + project.api_socket
        } else {
            wsuri = 'wss://' + project.api_socket + ':12019'
        }

        console.log('====> 裝置起始 Web Socket Server On: ' + wsuri)

        // 初始化 WebSocket
        project.websock = new WebSocket(wsuri)
        project.websock.onmessage = this.websocketonmessage
        project.websock.onopen = this.websocketonopen
        project.websock.onerror = this.websocketonerror
        project.websock.onclose = this.websocketclose

        console.log('====> websock', project.websock)
    }

    // 连接建立之后执行send方法发送数据
    websocketonopen () {
        project.need_watch_socket = true
        project.socket_status = true
        console.log('====> Web Socket 連線成功')

        // 先行送出 API TOKEN
        var actions = {
            // 補上 Token
            'model': 'open',
            'type': 'start'
        }
        project.websocklib.websocketsend(actions)
    }

    // 连接建立失败重连
    websocketonerror (e) {
        console.log('--> 檢測到 WebSocket 斷線事故', e)
        project.socket_status = false
    }

    // 数据接收
    websocketonmessage (e) {
        var redata = JSON.parse(e.data)
        console.log('--> WebSocket 接收資料: ', redata)

        // 設置收到的資料
        project.socketProcess = true
        project.socketData = redata
    }

    // 数据发送
    websocketsend (Data = {}) {
        // 補上 Token
        Data['OAUTH-API-KEY'] = project.auth_token
        Data['platform'] = 'course'
        // 顯示傳遞內容
        var jsonstr = JSON.stringify(Data)
        // 傳遞資料
        project.websock.send(jsonstr)
        console.log('--> WebSocket 傳遞: ' + jsonstr)
    }

    // 关闭
    websocketclose (e) {
        // console.log('断开连接', e)
        console.log('--> WebSocket 關閉連線: ' + JSON.stringify(e))
        project.socket_status = false
        // ====================
        // var vm = this
        setTimeout(() => {
            project.websocklib.initWebSocket()
        }, 1500);
    }

    // 強制關閉 WebSocket
    websocketend () {
        project.websock.close()
    }
}