// ---- SASS
import '@/sass/reset.sass'
import '@/sass/ui.sass'
import '@/sass/grid.sass'
import '@/sass/layout.sass'
import '@/sass/plugin.sass'
import '@/assets/css/animate.css'
import '@lottiefiles/lottie-player'
import 'font-awesome/css/font-awesome.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'

import $ from 'jquery'
import OwlCarousel from 'v-owl-carousel'
import VueQrcode from '@xkeshi/vue-qrcode'

import VueResource from 'vue-resource'
import VueCookies from 'vue-cookies'
import VueLocalStorage from 'vue-localstorage'
import VueVideoPlayer from 'vue-video-player'
import 'videojs-contrib-hls'

// 載入 Library
import Common from './library/Common'
import Apis from './library/Apis'

// Import FireBase
import firebase from 'firebase/app'
import WebSocketLib from './library/WebSocketLib'

// require videojs style
import 'video.js/dist/video-js.css'

Vue.config.productionTip = false
Vue.use(VueResource)
Vue.use(VueCookies)
Vue.use(VueLocalStorage)
Vue.component(VueQrcode.name, VueQrcode)
Vue.component('carousel', OwlCarousel)
Vue.use(VueVideoPlayer)

const configOptions = {
  apiKey: "AIzaSyBtjzm4l61fy-6_aJWOJbR6Va6ejSzKXsk",
  authDomain: "gvo-videoapp.firebaseapp.com",
  databaseURL: "https://gvo-videoapp.firebaseio.com",
  projectId: "gvo-videoapp",
  storageBucket: "gvo-videoapp.appspot.com",
  messagingSenderId: "33377823245",
  appId: "1:33377823245:web:fd9382545e8be050854344",
  measurementId: "G-BFYQYHRKV6"
};

firebase.initializeApp(configOptions)

// 當每一次跳轉頁面，都把頁面 loading 打開
router.afterEach(() => {
  /* 返回到最頂上 */
  $('*').animate({ scrollTop: 0 }, 'slow');
})

new Vue({
  router,
  render: h => h(App),
  data: {
    deviceType: false,
    site_path: process.env.VUE_APP_SITE_URL,
    api_path: process.env.VUE_APP_API_URL,
    api_socket: process.env.VUE_APP_SOCKET_URL,
    media_path: process.env.VUE_APP_MEDIA_URL,
    manager_uri: process.env.VUE_APP_MANAGER_URL,
    // == ==
    common: false,
    apis: false,
    websocklib: false,
    // == 系統參數 ==
    auth_token: false,
    user: {
      id: false,
      image: false,
      name: false,
      userID: false,
    },
    // == ==
    nav_meta: 'front-home',
    hide_page_status: false,
    // == Token ==
    token: '',
    devId: '',
    // == 系統狀態 ==
    system_version: process.env.VUE_APP_NODE,
    system_ready: false,
    padding_check: false,
    article_ready: false,
    //-- loading 控制
    isAllLoading: false,
    isSmLoading: false,
    // == 導引狀態
    isGuide: true,
    // == 行動號碼驗證 ==
    cellphoneStep: false,
    // == 國碼 ==
    country_codes: [
      '+1', '+61', '+64', '+44', '+84', '+86', '+886', '+852', '+853', '+81', '+82', '+60',
    ],
    country_code: '+886',
    //-------------
    //- 功能選單
    arrHeaderNav: [
      {
        title: '首頁',
        meta: 'front-home',
        status: true,
        type: 1
      },
      {
        title: '帳號申請',
        meta: 'private-apply',
        status: false,
        type: 1
      },
      {
        title: '教師登入',
        meta: process.env.VUE_APP_MANAGER_URL + '/login/teacher',
        status: true,
        type: 2
      }
    ],
    arrMenuNav: [
      {
        id: 'home',
        title: '我的課程',
        meta: 'home',
        type: 1,
        status: true,
      },
      {
        id: 'scheduled',
        title: '課程管理',
        meta: 'scheduled',
        type: 1,
        status: false,
      },
      {
        id: 'history',
        type: 1,
        title: '歷史課程',
        meta: 'history',
        status: false,
      },
      {
        id: 'bills',
        type: 1,
        title: '交易紀錄',
        meta: "bills",
        status: false,
      },
      {
        id: 'profile',
        type: 1,
        title: '個人資訊',
        meta: 'profile',
        status: false,
      }
    ],
    //- 日期篩選
    date_filter: false,

    isAlert: false,
    // 警報參數
    option_view: {
      title: false,
      text_1: false,
      text_2: false,
      text_3: false,
      course_text: false,
      option_1: false,
      option_2: false,
      option_1_fun: false,
      option_2_fun: false,
    },
    // == 新生學園所需參數
    new_park_view_id: false,
    new_park_view_details: false,
    new_park_view_course: false,
    // == 直播頁設置
    live_open_persent: false,
    live_view_course: false,
    live_view_status: false,
    live_view_anim: false,
    // == 是否為全系統課表開放 ==
    isAllOpen: true,
    setting: {
      base: {},
      metting: {},
      course: {},
      mp_course: {},
      cd_course: {},
    },
    // Socket Data
    socketProcess: false,
    socketData: false,
    //- 課程相關資料
    total_course: [
      { id: "MP", name: "會話班" },
      { id: "LP", name: "證照檢定班" },
      // { id: "GM", name: "文法專班" },
      // { id: "VB", name: "字彙加強班" },
      // { id: "RW", name: "閱讀寫作專班" },
      // { id: "YS", name: "在校生班" },
      // { id: "CM", name: "商用語言班" },
      // { id: "SS", name: "觀光會話班" },
      // { id: "DM", name: "體驗課程" },
      { id: "PC", name: "專業課程班" },
      { id: "CD", name: "兒少班" },
      { id: "PS", name: "個教班" },
      { id: "MS", name: "中學班" },
    ],
    total_langes: [
      { id: "EN", name: "美語" },
      { id: "JP", name: "日語" },
      { id: "KO", name: "韓語" }
    ]
  },
  mounted () {
    // 每次 router 更新，開都啟 loading
    this.$router.beforeEach((to, from, next) => {
      this.isSmLoading = true
      setTimeout(() => {
        next()
      }, 300)
    })
    // 每次 router 更新，都滾到頂端
    this.$router.afterEach(() => {
      $('html, body').scrollTop(0)
    })
  },
  async created () {
    // 先開載入中
    this.useLoading()

    // 處理基本工具
    this.common = new Common(this)
    this.apis = new Apis(this)
    this.websocklib = new WebSocketLib(this)

    // 確認裝置類型
    this.deviceType = this.common.checkDeviceIsPC()
    console.log('--> 裝置類型: ', this.deviceType)

    // 獲取系統資訊
    await this.apis.getSystemInfo()
    await this.$root.getSysSetting('mp_course')
    this.processMenuItems()

    // 起始 WebSocket
    await this.websocklib.initWebSocket()

    // 處理基本參數
    this.date_filter = this.common.getNowTime('date')
  },
  watch: {
  },
  methods: {
    // 開啟 Loading 狀態
    useLoading () {
      this.isSmLoading = true
    },
    // 關閉 Loding 狀態
    closeLoading () {
      this.isSmLoading = false
    },
    // 前往指定頁面
    goPage (_page, _type = 1, _params = {}) {
      // 針對內部頁面
      if (_type === 1) {
        this.$router.push({ name: _page, params: _params })
        if (_page === 'home' || _page === 'teachers' || _page === 'apply' || _page === 'timetable') {
          this.$root.nav_meta = _page
        } else {
          this.$root.nav_meta = ''
        }
      }

      // 針對外部頁面
      else if (_type === 2) {
        document.location.href = _page
      }
    },
    //- 開啟選項畫面
    openOptionView (_alert) {
      this.alert_type = 1
      this.isAlert = true
      this.option_view.title = (_alert.title) ? _alert.title : ""
      this.option_view.text_1 = (_alert.text_1) ? _alert.text_1 : ""
      this.option_view.text_2 = (_alert.text_2) ? _alert.text_2 : ""
      this.option_view.text_3 = (_alert.text_3) ? _alert.text_3 : ""
      this.option_view.course_text = (_alert.course_text) ? _alert.course_text : ""
      this.option_view.option_1 = (_alert.option_1) ? _alert.option_1 : ""
      this.option_view.option_2 = (_alert.option_2) ? _alert.option_2 : ""
      this.option_view.option_1_fun = (_alert.option_1_fun) ? _alert.option_1_fun : false
      this.option_view.option_2_fun = (_alert.option_2_fun) ? _alert.option_2_fun : false
    },
    // 關閉 Alert
    closeOptionView () {
      this.option_view_animate = false
      this.option_view.option_1_fun = false
      this.option_view.option_2_fun = false
      setTimeout(() => {
        this.option_view_status = false
        this.option_view.title = ''
        this.option_view.text_1 = ''
        this.option_view.text_2 = ''
        this.option_view.course_text = ''
        this.option_view.option_1 = ''
        this.option_view.option_2 = ''
      }, 300);
    },
    processMenuItems () {
      // 如果沒有點數的話
      if (!this.user.business_point) {
        this.arrMenuNav = [{
          id: 'home',
          title: '我的課程',
          meta: 'home',
          type: 1,
          status: true,
        },
        {
          id: 'history',
          type: 1,
          title: '歷史課程',
          meta: 'history',
          status: false,
        },
        {
          id: 'profile',
          type: 1,
          title: '個人資訊',
          meta: 'profile',
          status: false,
        }]
      }
    },
    // 進入新生內頁
    enterNewParkIn (_seriesId, _seriesDetails) {
      this.new_park_view_id = _seriesId
      this.new_park_view_details = _seriesDetails
      this.$router.push({ name: 'new_study_in', params: { new_study_id: _seriesId } })
    },
    // 進入課程內頁
    enterCourse (_courseID) {
      this.coures_view_id = _courseID
      this.$router.push({ name: 'course', params: { course_id: _courseID } })
    },
    // 開啟直播畫面
    async openLiveView (_courseId = false) {
      // 開啟載入中畫面
      this.useLoading()
      // 請求獲得課程資料
      await this.apis.getCourseDetail(_courseId, 'course', this.processLiveEnterSuccess, this.processLiveEnterError)
      // 關閉載入中畫面
      this.closeLoading()
    },
    // 處理課程進入直播功能
    processLiveEnterSuccess (_response) {
      if (_response.body.error_code !== 0) {
        return this.processMeetingEnterError(_response)
      }

      let course = _response.body.data

      // 課程尚未預約
      if (!course.course_roomid) {
        this.openOptionView({
          title: "發生問題",
          text_1: '課程直播尚未開放',
          text_2: '請稍後再重新嘗試',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return
      }

      // 學員沒有預約課程
      if (course.type_now_status === 0) {
        this.openOptionView({
          title: "課程尚未預約",
          text_1: '課程直播尚未開放',
          text_2: '請稍後再重新嘗試',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return
      }

      // 課程尚未開始
      if (course.type_now_status === 1) {
        this.openOptionView({
          title: "課程尚未開始",
          text_1: '課程直播尚未開放',
          text_2: '請稍後再重新嘗試',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return
      }

      // 課程已經結束
      if (course.type_now_status === 3) {
        this.openOptionView({
          title: "課程已經結束",
          text_1: '課程直播已經停止開放',
          text_2: '',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return
      }

      // 處理進入課程直播相關功能
      if (course.course_meetroom === 1 && course.order_live === true) {
        this.live_view_course = course
        this.live_view_status = true
        this.live_view_anim = true
      }
    },
    // 處理課程拒絕直播功能
    processLiveEnterError () {

    },
    // 開啟直播再現畫面
    async openLiveReappear (_courseId = false) {
      // 開啟載入中畫面
      this.useLoading()
      // 請求獲得課程資料
      await this.apis.getCourseDetail(_courseId, 'reappear', this.processReappearEnterSuccess, this.processReappearEnterError)
      // 關閉載入中畫面
      this.closeLoading()
    },
    processReappearEnterSuccess (_response) {
      if (_response.body.error_code !== 0) {
        return this.processMeetingEnterError(_response)
      }

      let course = _response.body.data

      // 學員沒有預約課程
      if (course.type_now_status === 0) {
        this.openOptionView({
          title: "課程尚未預約",
          text_1: '課程直播尚未開放',
          text_2: '請稍後再重新嘗試',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return
      }

      // 課程尚未開始
      if (course.type_now_status === 1) {
        this.openOptionView({
          title: "課程尚未開始",
          text_1: '課程直播尚未開放',
          text_2: '請稍後再重新嘗試',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return
      }

      // 處理進入課程直播相關功能
      this.live_view_course = course
      this.live_view_status = true
      this.live_view_anim = true

      console.log('處理進入課程直播相關功能...')
      this.$root.common.goLive(course.course_id, 'reappear')
    },
    processReappearEnterError () {

    },
    // 開啟專區課程畫面
    async openRegionCourse (_course) {
      // 處理所需的相關資料
      _course.course_name = _course.zhTitle
      _course.course_live_uri = this.media_path + _course.m3u8

      // 處理進入課程直播相關功能
      this.live_course_type = 'region'
      this.live_view_course = _course
      this.live_open_persent = true
      this.live_view_status = true
      this.live_view_anim = true

      console.log('處理進入專區播放相關功能...')
      this.$root.common.goLive(_course.id, 'region')
    },
    // 開啟會議室
    async openMeeting (_courseId = false) {
      // 開啟載入中畫面
      this.useLoading()
      // 請求獲得課程資料
      await this.apis.getCourseDetail(_courseId, 'course', this.processMeetingEnterSuccess, this.processMeetingEnterError)
      // 關閉載入中畫面
      this.closeLoading()
    },
    // 處理課程進入會議室功能
    processMeetingEnterSuccess (_response) {
      if (_response.body.error_code !== 0) {
        return this.processMeetingEnterError(_response)
      }

      let course = _response.body.data

      // 課程會議室尚未預約
      if (!course.course_roomid) {
        this.openOptionView({
          title: "發生問題",
          text_1: '課程會議室尚未開放',
          text_2: '請稍後再重新嘗試',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return
      }

      // 學員沒有預約課程
      if (course.type_now_status === 0) {
        this.openOptionView({
          title: "課程尚未預約",
          text_1: '課程會議尚未開放',
          text_2: '請稍後再重新嘗試',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return
      }

      // 課程尚未開始
      if (course.type_now_status === 1) {
        this.openOptionView({
          title: "課程尚未開始",
          text_1: '課程會議室尚未開放',
          text_2: '請稍後再重新嘗試',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return
      }

      // 課程已經結束
      if (course.type_now_status === 3) {
        this.openOptionView({
          title: "課程已經結束",
          text_1: '課程會議室已經停止開放',
          text_2: '',
          course_text: '',
          option_1: "瞭解",
          option_2: '',
          option_1_fun: false,
          option_2_fun: false
        })
        return
      }

      // 處理課程會議相關功能
      if (course.course_meetroom === 1) {
        // 鎖定螢幕為橫向
        this.core.lockScreen('landscape')
        // 開啟會議室
        this.meeting.openMeeting(this.$root.user.userID, this.$root.user.name, course.course_roomid)
      }
    },
    // 處理課程拒絕會議室功能
    processMeetingEnterError () {

    },
    // 關閉直播內頁
    exitLiveView () {
      this.live_view_anim = false
      setTimeout(() => {
        this.live_view_status = false
        // if (!this.live_good_status) {
        //   this.give_good_view_status = true
        //   this.give_good_view_anim = true
        // }
      }, 300);
    },
    // 獲取系統設定參數
    async getSysSetting (_type) {
      var vm = this
      await this.apis.getSystemSettings(_type, function (_response) {
        if (_response.body.error_code === 0) {
          // --------------------
          if (_type === 'base') {
            vm.setting.base = _response.body.data
          }
          // --------------------
          if (_type === 'metting') {
            vm.setting.metting = _response.body.data
          }
          // --------------------
          if (_type === 'course') {
            vm.setting.course = _response.body.data
          }
          // --------------------
          if (_type === 'mp_course') {
            vm.setting.mp_course = _response.body.data
          }
          // --------------------
          if (_type === 'cd_course') {
            vm.setting.cd_course = _response.body.data
          }
        } else {
          vm.openOptionView({
            title: "發生了點問題",
            text_1: _response.body.message,
            option_1: "瞭解",
          })
        }
      })
    },
    // ####################################
    // Firebase
    // ####################################
    // 請求發送驗證簡訊
    sendPhoneNumberCode (_phoneNumber, _success) {
      var vm = this
      console.log('--> 呼叫 FireBase Phone Auth...')
      var appVerifier = window.recaptchaVerifier
      firebase.auth().signInWithPhoneNumber(_phoneNumber, appVerifier)
        .then(function (confirmationResult) {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult
          vm.openOptionView({ title: "驗證簡訊", text_1: "驗證簡訊已經發送", option_1: "瞭解", })
          // 處理驗證成功的方法
          if (typeof _success === 'function') {
            _success(_phoneNumber)
          }
        }).catch(function (error) {
          vm.openOptionView({
            title: "目前發生問題",
            text_1: error,
            option_1: "瞭解",
          })
        })
    },
    // 請求驗證簡訊驗證碼
    confirmPhoneNumberCode (_phoneNumber, _code, _success, _showAnser = true) {
      var vm = this
      // eslint-disable-next-line no-undef
      confirmationResult.confirm(_code).then(function (result) {
        var user = result.user
        console.log('--> user: ', user)
        // 不再顯示簡訊驗證成功訊息
        // if (_showAnser) {
        //   vm.openOptionView({ title: "驗證簡訊", text_1: "簡訊驗證成功", option_1: "瞭解", })
        // }
        // 處理驗證成功的方法
        if (typeof _success === 'function') {
          _success(_phoneNumber)
        }
      }).catch(function (error) {
        if (_showAnser) {
          vm.$root.openOptionView({
            title: "目前發生問題",
            text_1: error,
            option_1: "瞭解",
          })
        }
      })
    },
    // 確認是否已經登入
    confirmIsLogin () {
      console.log('--> 開始驗證是否已經登入...')
      // 若是已經登入，就前往學員後台
      if (this.user.id) {
        console.log('--> 導向學員後台...')
        this.goPage("/student/main", 2)
      }
    },
  }
}).$mount('#app');
