<template lang="pug">
.guide-view
  //- 960
  .guide-wrapper.d-lg-none.d-md-felx.d-sm-flex.d-flex
    .guide-box-1
      .guide-content.text-align-center
        .logo-img
          img(src="@/assets/images/icon-1024@3x.png")
        .guide-text-group
          .guide-text 1對1個別指導，自由選課，
          .guide-text 讓外語成為生活的一部份！
        img.guide-hand-img(src="@/assets/images/obbject-2-x@3x.png")
        .guide-button-group
          button.button.btn-default.btn-sm.btn-width-auto.guide-button-1.mr-15(
            @click="goDownloadAndroidApp"
          )
            span Android App
          button.button.btn-default.btn-sm.btn-width-auto.guide-button-1(
            @click="goDownloadIosApp"
          )
            span iOS App
        //- .guide-button-group
        //-   button.button.btn-op-default.btn-sm.btn-width-auto.guide-button-2(
        //-     @click="closeGuide()"
        //-   )
        //-     span 我下載過了

  //- 700
  //- .guide-wrapper.d-lg-none.d-md-none.d-sm-none.d-flex
    //- .guide-box-2
      img.guide-bg-img(src="@/assets/image/guide-bg1.png")
      .guide-content.text-align-center
        .title-text 全新
        .logo-img
          img(src="@/assets/image/head-logo.png")
        .guide-text-group
          .guide-text 每天打開30分鐘
          .guide-text 用影片輕鬆聽懂美語、日語、韓語
        img.guide-hand-img(src="@/assets/image/guide-hand1.png")
        .guide-button-group
          button.button.btn-default.btn-sm.btn-width-auto.guide-button-1
            span 立即下載App
        //- .guide-button-group
          button.button.btn-op-default.btn-sm.btn-width-auto.guide-button-2(
            @click="closeGuide()"
          )
            span 我下載過了
</template>
<script>
export default {
  name: 'Guide',
  props: {
  },
  components: {
  },
  computed: {

  },
  created () {
  },
  data () {
    return {
      closeGuide () {
        this.$root.isGuide = false
      }
    };
  },
  methods: {
    // 下載 Android APP
    goDownloadAndroidApp () {
      window.open("https://play.google.com/store/apps/details?id=tw.com.edu1n1.course&hl=zh_TW&gl=US")
    },
    // 下載 iOS APP
    goDownloadIosApp () {
      window.open("https://apps.apple.com/tw/app/%E5%9C%B0%E7%90%83%E6%9D%91%E7%B7%9A%E4%B8%8A%E5%AD%B8/id1574812065")
    },
  }
}
</script>

<style lang="sass" scoped>
.guide-wrapper
  position: fixed
  left: 0
  right: 0
  top: 0
  bottom: 0
  width: 100%
  height: 100%
  z-index: 99
  // display: flex
  flex-flow: row nowrap
  justify-content: center
  align-items: center
  align-content: center
  background-color: rgba(0, 0, 0, 0.6)
  .guide-box-1
    max-width: 400px
    width: 80%
    position: relative
    display: flex
    flex-flow: row nowrap
    justify-content: center
    align-items: center
    align-content: center
    background: #ffffff
    margin: 0 auto 0 auto
    border-radius: 15px
    padding: 1.5rem 1.2rem
    .guide-content
      width: 100%
      height: 100%
      .logo-img
        width: 5rem
        margin: 1rem calc(50% - 2.5rem)
      .guide-text-group
        color: #2e50ff
        font-size: 1rem
        font-weight: 500
        margin: 1.5rem 0
      .guide-hand-img
        width: 13rem
        margin: 1rem auto 2rem auto
      .guide-button-group
        bottom: 4%
        margin-top: 1%
        position: relative
        .guide-button-1
          min-width: 10vmin
          font-size: 1rem
          padding: .8rem 1rem
        .guide-button-2
          margin-top: .5rem
          min-width: 10vmin
          font-size: 1rem
          padding: .8rem 1rem
          background: none
          color: #91949c
          text-decoration-line: underline

  .guide-box-2
    min-width: 300px
    width: 90%
    position: relative
    .guide-content
      width: 100%
      height: 100%
      position: absolute
      left: 0
      top: 0
      padding: 1rem
      text-align: centers
      .title-text
        margin-top: 5%
        line-height: 2rem
        color: #2e50ff
        font-size: 1.2rem
        font-weight: 500
      .logo-img
        margin-left: 10%
        width: 80%
      .guide-text-group
        color: #2e50ff
        font-size: .9rem
        font-weight: 500
        margin-top: 5%
      .guide-hand-img
        width: 70%
        margin-top: 5%
      .guide-button-group
        top: 3%
        margin-top: 1%
        position: relative
        .guide-button-1
          min-width: 10vmin
          font-size: .8rem
          padding: .8rem 1rem
        .guide-button-2
          min-width: 10vmin
          font-size: .8rem
          padding: .8rem 1rem
          background: none
          color: #91949c
          text-decoration-line: underline
.mr-15
  margin-right: 15px
</style>
