<template lang="pug">
//- home-main
.home-main
  .home-top
    .pro-col
      .home-title 我的課程
    .pro-col
      .home-info
        .info-title 尊榮點數
        .info-text {{ $root.user.business_point }}
    .pro-col
      .home-info
        .info-title 預約課程
        .info-text 0
    .pro-col
      .home-info
        .info-title 完成課程
        .info-text 0

  .home-main-content
    //- 日期組件
    DateCom(
      use_type="home",
      :date_turn="date_turn",
      :date_filter="date_filter",
      @dateTurn="dateTurn",
      @setDate="setCourseDate"
    )

    //- 今日推薦
    TodayRecommend(
      v-if="courseData.length"
      :courseData="courseData"
    )

  //- (無資料用這個) 
  .home-no-data(v-if="!courseData.length")
    .home-no-data-text
      span(v-if="$root.user.business_point > 0") 目前無課程，請新增課程
      span(v-else) 目前無課程
    button.home-no-data-btn(
      v-if="$root.user.business_point > 0"
      @click="$router.push({name:'new_scheduled'})"
    ) 新增排課

</template>
<script>
import DateCom from '@/components/date'
import TodayRecommend from '@/components/home/today-recommend'
export default {
  name: 'homeMain',
  props: ['switch_status'],
  components: {
    DateCom,
    TodayRecommend
  },
  data () {
    return {
      //- 課程表格切換
      timetable_switch: false,
      //- 日期開關
      date_turn: false,
      //- 日期篩選狀態
      date_filter: this.$root.common.getNowTime('date'),
      //- 課程清單
      courseData: []
    };
  },
  created () {
    this.getBusinessCourseList()
  },
  methods: {
    dateTurn () {
      this.$data.date_turn = !this.$data.date_turn
    },
    switchTimeTable (_status) {
      this.timetable_switch = _status
    },
    //- 切換右方時間表
    clickSwitch (_status) {
      this.$emit('clickSwitch', _status)
    },
    // 設定課程語言
    setCourseLange (_langID) {
      this.language_filter = _langID
    },
    // 設定課程類別
    setCourseType (_courseType) {
      this.class_filter = _courseType
    },
    // 設定課程日期
    setCourseDate (_courseDate) {
      this.date_filter = _courseDate
      this.getBusinessCourseList()
    },
    getBusinessCourseList () {
      let vm = this
      this.$root.apis.getBusinessCourseList(this.date_filter, function (_response) {
        vm.courseData = _response.body.data
      })
    }
  }
}
</script>
