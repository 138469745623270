<template lang="pug">
.video-temp
  .video-object.text-center
    #VideoPlayer.video-click.video-bg
      video-player.video-player-box(
        :options="playerOptions",
        customEventName="customstatechangedeventname",
        :webkit-playsinline="true",
        :playsinline="true",
        :x5-playsinline="true",
        @play="onPlayerPlay",
        @pause="onPlayerPause",
        @ended="onPlayerEnded",
        @ready="playerReadied"
      )
        .vjs-control-bar(:class="course_type ? 'dis-blck' : 'dis-none'")
    .live-info
      //- .info-photo
        img(src="@/assets/images/head.png")
      //- .info-group
        .info-status LIVE VIDEO
          //- span.info-time 00:10:37
        //- .info-eyenum
          img.eye-img(src="@/assets/images/visibility@3x.png")
          .eye-num 123123
          .clearfix
        .clearfix

      //- 人像
      //- .live-people
        .people-photo(v-for="n in 5", v-bind:class="'photo_' + n")
          img.photo-img(src="@/assets/images/head.jpg")
        .people-photo.photo_5
        .clearfix

    img.play-img.click-cursor(
      v-if="!playstatus",
      @click="startVideo",
      src="@/assets/images/play.png"
    )
    //- img.sm-play-img.click-cursor(
    //-   v-if="!playstatus",
    //-   src="@/assets/images/video-play-button@3x.png",
    //-   @click="startVideo()"
    //- )
    img.sm-play-img.click-cursor(
      v-else,
      :class="course_type === 'course' ? 'dis-blck' : 'dis-none'",
      src="@/assets/images/stop@3x.png",
      @click="stopVideo()"
    )
    img.zoom-img.click-cursor(
      :class="course_type === 'course' ? 'dis-blck' : 'dis-none'",
      src="@/assets/images/zoom@3x.png",
      @click="enterFullScreen()"
    )
</template>

<script>
import $ from 'jquery'
export default {
  props: [
    "course_id",
    "type",
    "source",
    "autoplay",
    "start_time",
    "course_type"
  ],
  watch: {
    // '$root.window_status' (_status) {
    //   // 進入橫向方式
    //   if (_status) {
    //     this.enterFullScreen()
    //   }

    //   // 進入直向方式
    //   else {
    //     this.exitFullscreen()
    //   }
    // }
  },
  data () {
    return {
      lastCheckStatus: false,
      move_persent: 0,
      checkHideMethod: false,
      checkScreenStatus: false,
      play_video_status: false,
      play_video_persent: 0,
      video_play_show: false,
      video_play_anim: false,
      videoPlayer: false,
      playerOptions: {
        // 不給予控制功能
        controls: true,
        // 靜音
        muted: false,
        // 全版
        fill: true,
        // 語言
        language: 'zh-tw',
        // 看起來是可以加速的比例
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        // 影片檔案規格

        sources: [

        ]
      },
      is_fullview: false,
      playstatus: false,
      videoType: false,
      hideAt: 0
    }
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    }
  },
  mounted () {
    if (this.course_type === 'course') {
      console.log('是直播')
      $('.vjs-control-bar').addClass("dis-none");
    }
    if (this.course_type !== 'course') {
      console.log('不是直播')
      $('.vjs-control-bar').addClass("dis-fle");
    }
  },
  created () {
    console.log('---> 初始化音視頻組件....')
    var vm = this
    // 監聽畫面狀態
    this.checkScreenStatus = setInterval(function () { vm.isFullscreen() }, 500);
  },
  methods: {
    // player event or listen state event
    playerStateChanged (playerCurrentState) {
      console.log('[' + this.course_id + ']player current update state', playerCurrentState)
    },
    // player is ready
    playerReadied (player) {
      this.videoPlayer = player
      console.log('[' + this.course_id + ']the player is readied: ', player)

      // 設置音視頻檔案
      if (this.source) {
        console.log('[' + this.attId + ']---> 指定使用檔案: ', this.source)
        this.videoPlayer.src({ type: 'application/x-mpegURL', src: this.source })
      }

      // 按照類別處理畫面
      this.videoType = this.videoPlayer.currentType()
      console.log('[' + this.course_id + ']---> 目前播放的檔案類型: ', this.videoType)

      $('.video-object,.video-player-box,video').css('min-height', '39rem')
      $('video').css('min-height', '39rem')

      // 自動起始播放
      if (this.autoplay) {

        // 有指定時間進行播放
        if (this.start_time) {
          console.log('--> 請求從指定秒數[' + this.start_time + ']開始播放')
          this.playTargetTime({ setTime: this.start_time })
        }

        // 無指定時間進行播放
        else {
          console.log('--> 請求直接播放影像')
          setTimeout(() => { this.startVideo() }, 300)
        }
      }
    },
    // 當音視頻被啟動
    onPlayerPlay (player) {
      this.playstatus = true
      this.$emit('playStatus', true)
      console.log('[' + this.course_id + ']player play!', player)
    },
    // 當音視頻被暫停
    onPlayerPause (player) {
      this.playstatus = false
      this.$emit('playStatus', false)
      console.log('[' + this.course_id + ']player pause!', player)
    },
    // 當音視頻已結束
    onPlayerEnded (player) {
      console.log('[' + this.course_id + ']player ended!', player)
    },
    // 啟動音視頻
    startVideo () {
      console.log('[' + this.course_id + ']--> start video..')
      this.videoPlayer.play()
      this.$emit('playStatus', true)
    },
    // 暫停音視頻
    stopVideo () {
      console.log('[' + this.course_id + ']--> stop video..')
      this.videoPlayer.pause()
      console.log('[' + this.course_id + ']--> 音視頻資訊: ', this.videoPlayer.toJSON())
      console.log('[' + this.course_id + ']--> 目前時間: ', this.videoPlayer.currentTime())
      this.$emit('playStatus', false)
    },
    // 返回當前時間
    getNowTime () {
      // 回傳目前時間
      return this.videoPlayer.currentTime()
    },
    // 獲取當前時間
    getCurrentTime () {
      // 請求暫停播放
      this.stopVideo()
      // 回傳目前時間
      let currentTime = this.videoPlayer.currentTime()
      this.$emit('tagTarget', {
        type: this.type,
        currentTime: currentTime
      })
    },
    // 自指定時間開始播放
    playTargetTime (_data) {
      console.log('[' + this.course_id + ']--> 自指定時間開始播放: ', _data)
      this.videoPlayer.currentTime(_data.setTime)
      this.videoPlayer.play()
    },
    // 啟動全螢幕
    enterFullScreen () {
      // 若視頻尚未準備好
      if (!this.videoPlayer) {
        return false
      }
      // 請求進行全螢幕
      console.log('--> 請求進行全螢幕...')
      this.videoPlayer.requestFullscreen()
    },
    // 離開全螢幕
    exitFullscreen () {
      // 若視頻尚未準備好
      if (!this.videoPlayer) {
        return false
      }
      // 請求進行全螢幕
      console.log('--> 請求離開全螢幕...')
      this.videoPlayer.exitFullscreen()
    },
    // 確認目前是否還在全屏
    isFullscreen () {
      if (this.$root.platform !== 'Android') {
        return false
      }

      var status = this.videoPlayer.isFullscreen()
      console.log('--> 確認目前是否還在全屏:', status)
      if (!status && this.lastCheckStatus) {
        console.log('    --> 開始鎖定螢幕..')
      }
      this.lastCheckStatus = status
    }
  },
  destroyed () {
    clearInterval(this.checkHideMethod)
    clearInterval(this.checkScreenStatus)
    console.log('--> 關閉 Video 物件...')
  }
}
</script>
<style lang="sass">
.vjs-control-bar
  top: 37rem
.vjs-control-bar .vjs-playback-rate
  display: none
.dis-none
  display: none !important
.dis-blck
  display: block !important
.dis-fle
  display: flex !important
</style>
<style lang="sass" scoped>
.video-temp
  width: 100%
  height: 100%
  background: #232323
  border-radius: 5px
.video-object
  position: relative
  width: 100%
  background: #232323
  border-radius: 5px
  .video-player-box
    margin: 0px auto
</style>