<template lang="pug">
//- 首頁
.home-index.page-index(v-if="!$root.isSmLoading")
  //- 內容 TOP (內有 搜尋bar 及 通知區塊 )
  ContentTop
  .row.home-content
    HomeMain(
      :switch_status="timetable_switch_status",
      @clickSwitch="clickSwitch"
    )
</template>
<script>
// import $ from 'jquery'
import ContentTop from '@/components/content-top/content-top'
import HomeMain from '@/components/home/home-main'
// import HomeTimeTable from '@/components/home/home-timetable'
export default {
  name: 'home',
  components: {
    ContentTop,
    HomeMain,
    // HomeTimeTable
  },
  data () {
    return {
      timetable_switch_status: false,
      //- 課程data
      timetable_data: [],
    };
  },
  async created () {
    // 關閉載入中
    setTimeout(() => { this.$root.closeLoading() }, 250)
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    clickSwitch (_status) {
      this.timetable_switch_status = _status
    }
  }
}
</script>
<style lang="sass">
</style>
