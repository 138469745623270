<template lang="pug">
//- 交易紀錄
.bills-index.page-Index
  //- 內容 TOP (內有 搜尋bar 及 通知區塊 )
  ContentTop

  .bills-top
    .bills-title 交易紀錄, 共 {{ total }} 筆
  .bills-content
    .bills-list-table-scroll
      table.bills-list-table
        thead
          tr
            th 項次
            th 交易ID
            th 交易時間
            th 交易敘述
            th 交易點數
            th 剩餘點數

        tbody
          tr(v-if="trades.length === 0")
            td.text-align-center(colspan="5") 目前無資料
          tr.text-align-center(v-else v-for="(trade,index) in trades")
            td {{ index + 1}}
            td {{ trade.tradeID }}
            td {{ trade.tradeTime }}
            td.text-align-left {{ trade.tradeDescribe }}
            td {{ trade.tradeMemberPoints }}
            td {{ trade.remainMemberPoints }}

  .list-bottom(v-if="trades.length !== 0")
    nav
      ul.pagination.pagination-rounded
        li.disabled(v-if="page > 1")
          a(@click="getAccountTrades(page, 1)", aria-label="Previous")
            i.fa.fa-chevron-left.first

        li(
          v-for="pre_page in totalPage",
          v-if="pre_page < page + 4 && pre_page > page - 4",
          :class="page == pre_page ? 'active' : ''"
        )
          a(@click="getAccountTrades(pre_page)")
            | {{ pre_page }}

        li(v-if="page < totalPage")
          a(@click="getAccountTrades(page, 2)", aria-label="Next")
            i.fa.fa-chevron-right.last
</template>
<script>
import $ from 'jquery'
import ContentTop from '@/components/content-top/content-top'

export default {
  name: 'Index',
  components: {
    ContentTop
  },
  data () {
    return {
      trades: [],
      page: 1,
      total: 0,
      totalPage: 0,
    };
  },
  async created () {
    // 請求進行更新學員資料
    // await this.$root.apis.getSystemInfo()
    // 關閉載入中畫面
    await this.$root.closeLoading()
    this.getAccountTrades()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    //- 回至頂部
    goTop () {
      $('.profile-index').animate({
        scrollTop: 0
      }, 200)
    },
    //- 取回所有交易紀錄
    getAccountTrades (_page = '', _opt = '') {
      if (_page) {
        this.page = (_page)
      }
      if (_opt === 1) {
        this.page = this.page - 1
      }
      if (_opt === 2) {
        this.page = this.page + 1
      }

      let vm = this
      this.$root.apis.getAccountTrades({
        page: this.page
      }, function (_response) {
        let response = _response.body
        vm.trades = response.data
        vm.total = response.total
        vm.totalPage = response.pages
        vm.goTop()
      })
    }
  }
}
</script>
<style lang="sass">
</style>
