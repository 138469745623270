import Vue from 'vue'
import VueResource from 'vue-resource'
import VueCookies from 'vue-cookies'
Vue.use(VueResource)
Vue.use(VueCookies)

var vm
var project

// ================
Vue.http.options.root = process.env.VUE_APP_API_URL
Vue.http.interceptors.push(function (_request, _next) {
    Vue.http.headers.common['OAUTH-API-KEY'] = Vue.localStorage.get('api-token')
    Vue.http.headers.common['Content-Type'] = 'application/json'
    // 處理一般程序
    _next(function (_response) {
        var data = _response.body
        // console.log('執行錯誤檢查: ' + data.error_code)
        if (data) {
            switch (data.error_code) {
                case 1101:
                    // 彈出警告視窗
                    project.openOptionView({
                        title: "重複登入的裝置",
                        text_1: "您在這個裝置的操作將受到限制",
                        text_2: "請嘗試重新登入",
                        option_1: "瞭解",
                        option_1_fun: function () {
                            // 強制導回首頁
                            document.location.href = '/'
                        },
                    })
                    break
                default:
                    break
            }
        }
    })
})

export default class APIs {
    constructor(_vm) {
        vm = this
        project = _vm
    }

    /**
     * 獲取後端系統狀態
     * @param {*} _success 
     * @param {*} _error 
     */
    async getSystemStatus (_success, _error) {
        await Vue.http.get('status').then(_success, _error)
    }

    /**
     * 獲取 Token 資訊
     */
    async getSystemInfo () {
        // 啟動載入中畫面
        project.useLoading()
        await Vue.http.get('member/getStatus', {
            params: {
                ver: 'course-enterprise',
                platform: 'web'
            }
        }).then(function (_response) {
            // 關閉載入中畫面
            project.closeLoading()
            // 獲得目前回傳的資料
            let response = _response.body
            // 判斷是否有狀況
            if (response.error_code === 0) {
                // 處理 API Token
                project.auth_token = response.api_key
                Vue.localStorage.set('api-token', project.auth_token)

                // 處理獲得的資訊
                if (response.UserData.login_platform !== 6) {
                    return
                }

                // 處理獲得的個人資料
                project.user = response.UserData.uData
            }
        })
    }

    /**
     * 獲得系統設定參數
     * @param {*} _type 
     */
    async getSystemSettings (_type = '', _success, _error) {
        await Vue.http.get('manager/setting/getSettings', {
            params: {
                type: _type
            }
        }).then(_success, _error)
    }

    /**
     * 更新學員資料
     * @param {*} _type 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     * @returns 
     */
    async setUserData (_type = 1, _data = false, _success, _error) {
        var formData

        if (_type === 1) {
            formData = this.$data.userData
            formData.formType = 1
            // ===========================
            if (formData.userNewPwd !== '' || formData.userNewPwd2 !== '') {
                if (formData.userNewPwd !== formData.userNewPwd2) {
                    project.openOptionView({
                        title: '發生了點問題',
                        text_1: '需要變更的密碼不相同喔',
                        option_1: '瞭解',
                    })
                    return false
                }
                // =========================================
                formData.password = formData.userNewPwd
                // =========================================
            }
        }

        if (_type === 2) {
            formData = {
                formType: 2,
                image: _data
            }
        }

        if (_type === 4) {
            formData = {
                formType: 4,
                userCPhone: _data
            }
        }

        await Vue.http.post('student/center/baseSetting', formData).then(_success, _error)
    }

    /**
     * 請求使用電話號碼向後端獲取資料
     * @param {*} _userPhone 
     * @param {*} _success 
     * @param {*} _error 
     */
    async usePlatformByPhoneNumber (_userPhone = false, _success, _error) {
        await Vue.http.post('member/login', {
            selectAcc: 6,
            userPhone: _userPhone,
            version: project.system_version_number,
            device: 'phone'
        }).then(_success, _error)
    }

    /**
     * 請求後端確認號碼是否在白名單中
     * @param {*} _phoneNumber 
     * @param {*} _success 
     * @param {*} _error 
     */
    async isWhileListByCellPhone (_phoneNumber, _success, _error) {
        await Vue.http.post('member/confirmStudentPhoneWhileList', {
            phone: _phoneNumber,
        }).then(_success, _error)
    }

    /**
     * 請求後端驗證號碼是否使用白名單號碼與驗證碼
     * @param {*} _phoneNumber 
     * @param {*} _authCode 
     * @param {*} _success 
     * @param {*} _error 
     */
    async authStudentCellPhone (_phoneNumber, _authCode, _success, _error) {
        await Vue.http.post('member/authStudentPhoneWhileListCode', {
            phone: _phoneNumber,
            code: _authCode
        }).then(_success, _error)
    }

    /**
     * 請求使用帳密向後端獲取資料
     * @param {*} _loginData 
     * @param {*} _success 
     * @param {*} _error 
     */
    async usePlatformByPassword (_loginData = false, _success, _error) {
        await Vue.http.post('member/login', _loginData).then(_success, _error)
    }

    /**
     * 請求獲得學員資料
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentDetail (_success, _error) {
        await Vue.http.get('student/center/getDetail').then(_success, _error)
    }

    /**
     * 請求更新學員資料
     * @param {*} _detail 
     */
    async putStudentDetail (_detail = false, _success, _error) {
        await Vue.http.post('student/center/baseSetting', _detail).then(_success, _error)
    }

    /**
     * 請求獲得指定日期的課程清單
     * @param {*} _date 
     */
    async getBusinessCourseList (_date, _success, _error) {
        Vue.http.get('student/business/getCourseList/' + _date).then(_success, _error)
    }

    /**
     * 請求獲得主要帳號下的所有課程
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getBusinessMainCourseList (_params, _success, _error) {
        Vue.http.get('student/business/GetMainCourseList', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 獲得主題課程資訊
     * @param {*} _courseId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getBusinessCourseDetail (_courseId, _success, _error) {
        Vue.http.get('student/business/GetCourseDetail/' + _courseId).then(_success, _error)
    }

    /**
     * 獲取主帳號下所有交易紀錄
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getAccountTrades (_params = {}, _success, _error) {
        Vue.http.get('student/business/GetMainAccountTrades', {
            params: _params
        }).then(_success, _error)
    }

    /** 
     * ################################################################################################
     * --- 線上學系統 APIs
     * ################################################################################################
     */

    /**
     * 請求獲取學員指定日期的推薦課程
     * @param {*} _courseType 
     * @param {*} _langID 
     * @param {*} _courseDate 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentRecommendCourse (_courseType, _langID, _courseDate, _success, _error) {
        await Vue.http.get('student/course/getStudentTodayRecommend/' + _courseType + '/' + _langID + '/' + _courseDate).then(_success, _error)
    }

    /**
     * 請求獲取學員課表
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentCourseList (_courseLang = false, _courseType = false, _courseDate = false, _success, _error) {
        await Vue.http.get('student/course/getStudentCourses/' + _courseLang + '/' + _courseType + '/' + _courseDate).then(_success, _error)
    }

    /**
     * 獲取學員今日的課程紀錄
     * @param {*} _courseLang 
     * @param {*} _courseType 
     * @param {*} _courseDate 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentTodayCourseList (_courseLang = false, _courseType = false, _courseDate = false, _success, _error) {
        await Vue.http.get('student/course/getStudentTodayCourses/' + _courseLang + '/' + _courseType + '/' + _courseDate).then(_success, _error)
    }

    /**
     * 獲取學員舊的課程紀錄
     * @param {*} _courseLang 
     * @param {*} _courseType 
     * @param {*} _courseDate 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentOldCourseList (_courseLang = false, _courseType = false, _courseDate = false, _success, _error) {
        await Vue.http.get('student/course/getStudentOldCourses/' + _courseLang + '/' + _courseType + '/' + _courseDate).then(_success, _error)
    }


    /**
     * 取得單一課程資料
     * @param {*} _courseID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getCourseDetail (_courseID, _type, _success, _error) {
        await Vue.http.get('student/course/getCourseDetail', {
            params: {
                course_id: _courseID,
                course_type: _type
            }
        }).then(_success, _error)
    }

    /**
     * 取得課程上課學員清單
     * @param {*} _courseID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async GetCourseStudents (_courseID, _success, _error) {
        await Vue.http.get('student/business/GetCourseStudents/' + _courseID).then(_success, _error)
    }

    /**
     * 請求獲取團體班課表
     * @param {*} _langID 
     * @param {*} _courseType 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getGroupCourseList (_langID, _courseType, _courseDate, _success, _error) {
        await Vue.http.get('student/course/getMainCourses/' + _courseType + '/' + _langID + '/' + _courseDate).then(_success, _error)
    }

    /**
     * 請求獲取直播再現課表
     * @param {*} _success 
     * @param {*} _error 
     */
    async getLiveCourseList (_success, _error) {
        await Vue.http.get('student/course/getLiveCourses').then(_success, _error)
    }

    /**
     * 預約團體班課程
     * @param {*} _courseID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async registerGroupCourse (_courseID, _success, _error) {
        await Vue.http.get('student/course/newMainCourse', {
            params: {
                course_id: _courseID,
                watchtype: 'bs-meeting'
            }
        }).then(_success, _error)
    }

    /**
     * 預約團體班課程
     * @param {*} _courseID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async liveGroupCourse (_courseID, _success, _error) {
        await Vue.http.get('student/course/liveMainCourse', {
            params: {
                course_id: _courseID,
                watchtype: 'bs-live'
            }
        }).then(_success, _error)
    }

    /**
     * 預約直播再現課程
     * @param {*} _courseID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async liveReappearCourse (_courseID, _success, _error) {
        await Vue.http.get('student/course/liveReappearCourse', {
            params: {
                course_id: _courseID
            }
        }).then(_success, _error)
    }

    /**
     * 購買專區課程
     * @param {*} _courseID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async newRegionCourse (_courseID, _success, _error) {
        await Vue.http.get('student/course/newRegionCourse', {
            params: {
                course_id: _courseID
            }
        }).then(_success, _error)
    }

    /**
     * 獲取學員點數交易紀錄
     * @param {*} _page 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentPointTrades (_type = 1, _page = 1, _success, _error) {
        await Vue.http.get('student/center/getStudentTrades', {
            params: {
                type: _type,
                coursePage: _page
            }
        }).then(_success, _error)
    }

    /**
     * 獲取學員點數方案清單
     * @param {*} _page 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getStudentPointPlans (_page = 1, _success, _error) {
        await Vue.http.get('student/center/getStudentPlans', {
            params: {
                coursePage: _page
            }
        }).then(_success, _error)
    }

    /**
     * 獲取學員購方案清單
     */
    async getALlProgramLists (_success, _error) {
        await Vue.http.get('student/programs/getProgramLists').then(_success, _error)
    }

    /**
     * 請求建立方案訂單
     * @param {*} _order 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createStudentPlanOrder (_order, _success, _error) {
        await Vue.http.post('student/programs/createOrder', _order).then(_success, _error)
    }

    /**
     * Apple InPurchase 完成通報
     * @param {*} _order 
     * @param {*} _success 
     * @param {*} _error 
     */
    async appleInPurchaseSuccess (_orderNumber, _tradeNo, _success, _error) {
        await Vue.http.post('webhook/applePaymented/' + _orderNumber, {
            TradeNo: _tradeNo
        }).then(_success, _error)
    }

    /**
     * 通報 APP Log
     * @param {*} _log 
     */
    async putAppLog (_log) {
        await Vue.http.post('log/app', _log)
    }

    /**
     * 請求登出系統
     */
    async logout () {
        await Vue.http.get('member/logout').then(function (_response) {
            if (_response.body.error_code === 0) {
                // 登出後前往頁面
                project.goPage('first-page')
            }
        })
    }

    /**
     * 獲取新生學園列表
     * @param {*} _page 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getSeries (_page = 0, _lang = '', _success, _error) {
        await Vue.http.get('student/course/getSeriesList', { params: { page: _page, courseLang: _lang } }).then(_success, _error)
    }

    /**
     * 獲取指定影片的目標網址
     * @param {*} _videoId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getVideoUri (_videoId, _success, _error) {
        await Vue.http.get('student/course/getSeriesCourseLiveUri', { params: { video_id: _videoId } }).then(_success, _error)
    }

    // 獲得專區課程列表
    async getRetionCourseList (_page, _type, _mainType, _subType, _success, _error) {
        await Vue.http.get('student/course/getRegionCourseList', {
            params: {
                page: _page,
                type: _type,
                mainType: _mainType,
                subType: _subType
            }
        }).then(_success, _error)
    }

    async setApplyAccount (_data, _success, _error) {
        await Vue.http.post("Business/SetApplyUser", _data).then(_success, _error)
    }
}