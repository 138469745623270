<template lang="pug">
//- B端 header - nav 選單 - 手機版
.container-fluid
  nav.nav-mobile.row.align-items-center.flex-nowrap.justify-content-between
    .col-auto
      img.nav-logo.cursor-pointer(
        src="@/assets/image/logo.png",
        @click="$root.goTop()"
      )

    //- menu 按鈕
    .col.text-align-right.flex-shrink-0
      a.nav-mobile-open-button(@click="navActive(true)")
        i.fa.fa-bars

    //- 選單
    ul.nav-mobile-list.flex-column.align-items-stretch.flex-nowrap(
      :class="{ active: boolNavActive }"
    )
      li.nav-mobile-list-item.text-align-right
        a.nav-mobile-close-button(@click="navActive(false)")
          i.fa.fa-times
      li.nav-mobile-list-item.text-align-center(v-for="nav in arrHeaderNav" v-if="nav.status")
        a(@click="clickNav(nav.meta, nav.type)") {{ nav.title }}
      //- li.nav-mobile-list-item.text-align-center
      //-   a(@click="clickStart()") 開始學習
</template>
<script>
export default {
  name: 'HeaderNavDeskTop',
  components: {},
  props: {
    arrHeaderNav: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      boolNavActive: false
    };
  },
  methods: {
    // navActive
    navActive (isActive) {
      this.boolNavActive = isActive
    },
    clickNav (meta, type) {
      this.$emit('clickNav', meta, type)
      setTimeout(() => {
        this.navActive(false)
      }, 300)
    },
    clickStart () {
      this.$emit('clickStart')
    },
  }
}
</script>
